import React from "react";

import './Newspage.css';
import img from '../newsimg/newsimg2.webp'
function Newstwo(){


return (
 
  <div className="newspagewrapper">
      <div className="newspage-inner">
        <div className="newspage-title">Tata AutoComp signs JV with Prestolite Electric to supply EV powertrain solutions in India</div>
        <div className="newspage-date">July 26, 2016</div>
        <div className="newspage-img"><img className="np-img" src={img} /></div>
        <div className="newspage-text">New Delhi: Homegrown auto component maker Tata AutoComp Systems on Friday entered into a joint venture with Chinese supplier Prestolite Electric Beijing to design, engineer, manufacture and supply powertrain solutions for the Indian electric vehicle market. </div>
        <div className="newspage-text">The joint venture will have access to technologies for various powertrain products like motors, controllers as well as integrated drivetrain (integrated motor, inverter and transmission), Tata AutoComp said in a release. </div>
        <div className="newspage-text">Speaking on the occasion, Arvind Goel, MD & CEO Tata AutoComp Systems said “Tata AutoComp has always been on the forefront in introducing new technologies in India. We are pleased to partner with the Broad Ocean Motor Group through Prestolite Electric Beijing and Shanghai E-Drive as they have the latest technology and are amongst the top players in powertrain products for electric vehicles. Through this JV, we will serve all segments of the EV industry from two and three-wheelers, passenger vehicles, commercial vehicles and buses.”</div>
        <div className="newspage-text">Tata AutoComp’s offerings to the emerging EV segment now include Motors, Controllers, integrated drivetrain (integrated motor, inverter and reducer), battery pack, BMS, battery thermal management system, battery cooling plates, inverter cooling modules, chillers, e-compressors, radiators as well as EV chargers required to charge the electric vehicle.</div>
        <div className="newspage-text">Commenting on the partnership Charles Lu (Chairman Broad Ocean Motor Group, China) said “We are glad to partner with Tata AutoComp Systems Limited, a leading auto-component conglomerate in India. Through Tata AutoComp, we wish to serve the emerging Electric Vehicle market in India and are committed to support the JVC with latest technologies.” Prestolite Electric is owned by the Broad Ocean Motor Group, China which also owns Shanghai E-Drive Solutions.</div>


      </div>




  </div>





);




}
export default Newstwo;