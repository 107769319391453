import { collection, addDoc, deleteDoc, query, doc, where, getDocs, updateDoc } from "firebase/firestore";

import { firestore } from "./firebase-setup";


export async function queryQuestion() {
  try {
    const docRef = await getDocs(collection(firestore, "questionpaychex"));

    let list = docRef.docs.map((snapDoc) => {
      let data = snapDoc.data();
      data = { ...data, id: snapDoc.id };
      return data;
    });
    return list;
  } catch (err) {
    console.log(err);
  }
}
export async function addQuestion(item) {
  console.log("item===", item)
  try {
    const docRef = await addDoc(collection(firestore, "questionpaychex"), item);
    return docRef;
  } catch (err) {
    console.log(err);
  }
}

export async function deleteQuestion(key) {
  try {
    await deleteDoc(doc(firestore, "questionpaychex", key));
  } catch (err) {
    console.log(err);
  }
}

export async function submitTempPaperResult(item) {
  console.log("item===", item)
  try {

    const citiesRef = collection(firestore, "t_paperresultpaychex");
    const q = query(citiesRef, where("userid", "==", item.userid));
    const querySnapshot = await getDocs(q);
    let list = [];
    querySnapshot.docs.forEach((snapDoc) => {
      let data = snapDoc.data();
      list.push({ ...data, id: snapDoc.id });
    });
    console.log("list====", list);

    if (list.length > 0) {
      let one = list[0];
      await updateDoc(doc(firestore, "t_paperresultpaychex", one.id), item);
    } else {
      const docRef = await addDoc(collection(firestore, "t_paperresultpaychex"), item);
      return docRef;
    }

  } catch (err) {
    console.log(err);
  }
}


export async function submitPaperResult(item) {
  console.log("item===", item)
  try {
    const docRef = await addDoc(collection(firestore, "t_paperresultpaychex"), item);
    return docRef;
  } catch (err) {
    console.log(err);
  }
}

export async function queryPaperResult() {
  try {
    const docRef = await getDocs(collection(firestore, "t_paperresultpaychex"));

    let list = docRef.docs.map((snapDoc) => {
      let data = snapDoc.data();
      data = { ...data, id: snapDoc.id };
      return data;
    });
    return list;
  } catch (err) {
    console.log(err);
  }
}


export async function getPaperResult(id) {
  try {
    const docRef = await getDocs(collection(firestore, "paperresult"));

    let list = docRef.docs.map((snapDoc) => {
      let data = snapDoc.data();
      data = { ...data, id: snapDoc.id };
      return data;
    });

    return list.find(item => item.id === id);
  } catch (err) {
    console.log(err);
  }
}
export async function queryUserPaperResult(userid) {
  try {
    const citiesRef = collection(firestore, "t_paperresult");

    const q = query(citiesRef, where("userid", "==", userid));
    const querySnapshot = await getDocs(q);

    let list = querySnapshot.docs.map((snapDoc) => {
      let data = snapDoc.data();
      data = { ...data, id: snapDoc.id };
      return data;
    });
    return list;
  } catch (err) {
    console.log(err);
  }
}

export async function deletePaperresult(key) {
  try {
    await deleteDoc(doc(firestore, "paperresult", key));
  } catch (err) {
    console.log(err);
  }
}