import React from "react";

import './About.css'

class Absection extends React.Component{
    state = {
        showsection1: true,
        showsection2: false,
        showsection3: false,
        showsection4: false,
        showsection5: false,
        
    }
    

     render(){

        const {showsection1,showsection2,showsection3,showsection4,showsection5} = this.state;
        
         return(
                <div className="absectionwrapper">



                    <div className="absmenuwrapper">
                        {showsection1 && (<Section1/>)}
                        {showsection2 && (<Section2/>)}
                        {showsection3 && (<Section3/>)}
                        {showsection4 && (<Section4/>)}
                        {showsection5 && (<Section5/>)}
                        </div>



                    <div className="absection-top">
                        <div className="absection-menu">
                        
                            <div className="abs-buttom" onClick={() => this.setState({
                                showsection1: true,
                                showsection2: false,
                                showsection3: false,
                                showsection4: false,
                                showsection5: false,
                              
                            
                            })} >
                            
                            <div className="abs-title">Corporate Vision</div>
                            
                            </div>

                        </div>
                        <div className="absection-menu">
                        
                        <div className="abs-buttom" onClick={() => this.setState({
                            showsection1: false,
                            showsection2: true,
                            showsection3: false,
                            showsection4: false,
                            showsection5: false,
                        
                        })} >
                        
                        <div className="abs-title">Our Mission</div>
                        
                        </div>

                    </div>
                    <div className="absection-menu">
                        
                        <div className="abs-buttom" onClick={() => this.setState({
                            showsection1: false,
                            showsection2: false,
                            showsection3: true,
                            showsection4: false,
                            showsection5: false,
                        
                        })} >
                        
                        <div className="abs-title">Core Values</div>
                        
                        </div>

                    </div>

                    <div className="absection-menu">
                        
                        <div className="abs-buttom" onClick={() => this.setState({
                            showsection1: false,
                            showsection2: false,
                            showsection3: false,
                            showsection4: true,
                            showsection5: false,
                        
                        })} >
                        
                        <div className="abs-title">Business Philosophy</div>
                        
                        </div>

                    </div>

                    <div className="absection-menu">
                        
                        <div className="abs-buttom" onClick={() => this.setState({
                            showsection1: false,
                            showsection2: false,
                            showsection3: false,
                            showsection4: false,
                            showsection5: true,
                        
                        })} >
                        
                        <div className="abs-title">Enterprise Spirit</div>
                        
                        </div>

                    </div>







                        </div>

                        

                </div>
         );
     }

}
const Section1 = () =>(
    <div className="absection-inner">
    
    <div className="abs-inner-title">Broad-Ocean Motor’s Vision</div>
<div className="abs-text">Broad-Ocean Motor is committed in becoming a Global Supplier for energy efficient/Green Motors and Drive Systems!</div>
    
    </div>
    
    ) 
const Section2 = () =>(
<div className="absection-inner">
    <div className="abs-inner-title">Broad-Ocean Motor’s Mission</div>
    <div className="abs-text">To be the world’s leader in producing the best motors and drive systems.  We will drive innovation to realize value for our customers, investors, employees, and other partners, and faithfully fulfill social responsibilities by leading the way in green and environmentally friendly solutions.</div>
        

</div>

) 
const Section3 = () =>(
    <div className="absection-inner">
    
    <div className="abs-inner-title">Core Values</div>
    <div className="abs-text">Broad-Ocean Motor values integrity and pragmatism, collaboration, innovation, constant improvement, and putting people first</div>
    
    </div>
    
    ) 
    const Section4 = () =>(
        <div className="absection-inner">
        
        <div className="abs-inner-title">Business Philosophy</div>
    <div className="abs-text">Market-oriented, Innovation and Development</div>
        
        </div>
        
        ) 

    const Section5 = () =>(
        <div className="absection-inner">
        
        <div className="abs-inner-title">Enterprise Spirit</div>
    <div className="abs-text">Unity, Dedication, Pioneering first</div>
        
        </div>
        
        ) 

    


export default Absection;