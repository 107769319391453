import { Button, Checkbox, Input, message, Radio, Rate, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./index.module.css";
import * as api from '../firebase/firestore';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import s4 from '../img/CelebrationDinner Schedule.jpg'
//import Context from '../../store';
export default function SearchList() {

  const [list, setList] = useState([]);
  const [showError, setShowError] = useState(false);
  const [userid, setUserid] = useState(localStorage.getItem("userid"));
  const [inputUserid, setInputUserid] = useState(null);
  
 



  useEffect(() => {


    search();
  }, [userid])


  const search = async () => {
    let newList = await api.queryQuestion();
    console.log("newList", newList)


    if (userid) {
      let r = await api.queryUserPaperResult(userid);
      if (r && r.length > 0) {
        let one = r[0];
        newList.forEach((item, i) => {
          console.log("one.list", one.list);

          if (one.list && (one.list.length - 1 >= i && typeof (one.list[i].value) != "undefined")) {
            item.value = one.list[i].value;
          }
        })
      }
    }

    setList(newList);
  }

  const submit = async () => {
    // let val = list.find(item => !item.value && item.type !== 3);
    // if (val) {
    //   setShowError(true);
    //   message.error("Please Input Question!");
    //   return;
    // } else {
    //   setShowError(false);
    // }
    let res = await api.submitPaperResult({
      time: moment().format("YYYY-MM-DD HH:mm:ss"),
      list: list,
      userid: userid
    });

    message.success("Submit Success");
    
    // setUserid(setInputUserid)
  }


  const tempSubmit = async () => {


    let res = await api.submitTempPaperResult({
      time: moment().format("YYYY-MM-DD HH:mm:ss"),
      list: list,
      userid: userid
    });
  }

  // const dealError = () => {
  //   if (showError) {
  //     let val = list.find(item => !item.value && item.type !== 3);
  //     if (val) {
  //       setShowError(true);

  //     } else {
  //       setShowError(false);
  //     }
  //     console.log("11");
  //     setList([...list]);
  //   }
  // }
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const renderQuestion = (item) => {
    if (item.type === 1) {
      return <div>
        <Checkbox.Group value={item.value} onChange={(e) => {
          console.log("e", e);
          // if (!userid) {
          //   message.error("Please create Survey Key and remember it");
          //   return;
          // }
          item.value = e;
          tempSubmit();
          //dealError();
          setList([...list]);

        }}>
          {item.options.map((option) => {
            return <div key={option.v}>
              <Checkbox value={option.v}></Checkbox>
              {/* <span style={{ marginRight: 2, marginLeft: 10 }}>{option.v}:</span> */}
              <span style={{ marginRight: 2, marginLeft: 10 }}> </span>
              <span>{option.title}</span>
            </div>
          })}
        </Checkbox.Group>
      </div>;
    } else if (item.type === 2) {
      return <textarea style={{ paddingLeft: '10px' }} onChange={(e) => {
        console.log("e", e);
        // if (!userid) {
        //   message.error("Please create Survey Key and remember it");
        //   return;
        // }
        item.value = e.target.value;
        setList([...list]);
        tempSubmit();
        //dealError();
      }} value={item.value}></textarea>;
    } else if (item.type === 3) {
      return <div></div>
      // <Rate count={10} value={item.value} onChange={(e) => {
      //   console.log("e", e);
      //   if (!userid) {
      //     message.error("Please create userid and remember it");
      //     return;
      //   }
      //   console.log("list", list)

      //   item.value = e;
      //   setList([...list]);
      //   tempSubmit();
      //   dealError();
      // }} />;;
    } else if (item.type === 4) {
      return <div>
        <Radio.Group value={item.value} onChange={(e) => {
          console.log("e", e);
          // if (!userid) {
          //   message.error("Please create Survey Key and remember it");
          //   return;
          // }
          item.value = e.target.value;
          setList([...list]);
          tempSubmit();
          //dealError();
        }}>
          {item.options.map((option) => {
            return <div key={option.v}>
              <Radio value={option.v}>{option.title}</Radio>

            </div>
          })}
        </Radio.Group>
      </div>;
    }else if (item.type === 5) {
      return <input style={{ paddingLeft: '10px' }} onChange={(e) => {
        console.log("e", e);
        // if (!userid) {
        //   message.error("Please create Survey Key and remember it");
        //   return;
        // }
        item.value = e.target.value;
        setList([...list]);
        tempSubmit();
        //dealError();
      }} value={item.value}></input>;
    }else if (item.type === 6) {
      return     <DatePicker
      showIcon
      selected={startDate}
      showTimeSelect
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy h:mm aa"
      showTimeInput
      onChange={(date) => 
        
        {
          setStartDate(date);
          // if (!userid) {
          //   message.error("Please create Survey Key and remember it");
          //   return;
          // }
          item.value = date.toString();
          setList([...list]);
          tempSubmit();
          //dealError();
      }

      }
    /> 
    }else if (item.type === 7) {
      return     <DatePicker
      showIcon
      selected={startDate1}
      showTimeSelect
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy h:mm aa"
      showTimeInput
      onChange={(date) => 
        
        {
          setStartDate1(date);
          // if (!userid) {
          //   message.error("Please create Survey Key and remember it");
          //   return;
          // }
          item.value = date.toString();
          setList([...list]);
          tempSubmit();
          //dealError();
      }

      }
    /> 
    }
  }

  const createUserid = () => {
    let userid = Math.floor(Math.random() * 1000) + "u" + Math.floor(Math.random() * 1000);
    setUserid(userid);
    localStorage.setItem("userid", userid);
  }

  return (<>
    <div className={styles.searchList} >
   
    {/* <Button type='primary' style={{ width: 210, marginTop: 20 }} onClick={() => {
          createUserid();
        }}>Create a random Survey Key</Button>
        <div style={{ fontSize: 15, marginTop: 10 }}>Created or logged in key with: {userid}</div> */}
      {/* {!userid ?
        <Button type='primary' style={{ width: 210, marginTop: 20 }} onClick={() => {
          createUserid();
        }}>Create a random Survey Key</Button> : <div style={{ fontSize: 15 }}>Key:{userid}</div>
      } */}

      
      {/* <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>
        <Input placeholder='Please input Survey Key' style={{ height: 40, width: 210 }} onChange={(e) => {
          setInputUserid(e.target.value);
        }}></Input>
        <Button type='primary' style={{  marginLeft: 20 }} onClick={() => {
          setUserid(inputUserid);
        }}>Find your Key or Create the key by yourself</Button> 
        
       
      </div> */}

 {/* {!userid ?
        <Button type='primary' style={{  marginLeft: 20 }} onClick={() => {
          setUserid(inputUserid);
        }}>Find your Key or Create the key by yourself</Button> 
         :<div style={{ fontSize: 15, marginLeft: 20 }}>Start key with:{userid}</div>
} */}
        {/* <Button type='primary' style={{ width: 100, marginLeft: 20 }} onClick={() => {
          setUserid(inputUserid);
          
        }}>Login</Button> */}
        {/* <Button type='primary' style={{  marginLeft: 20 }} onClick={() => {
          setUserid(setInputUserid);

        }}>Clear Survey Key</Button> */}

      <div className={styles.listItems}>
        {
          list.map((item, index) => {
            return <div key={item.id} className={styles.listItem}>
              <div className={styles.right} 
              
              style={ item.type === 3 ? { border: "0px solid #ff2222" }:
                { border: (showError && !item.value) ? "1px solid #ff2222" : "1px solid #ffffff", padding: 10, boxSizing: "border-box" }   
              
              
              } 
              
              
              >
               
               
                {
                 item.type === 3 ? <div style={{ marginBottom: 10,fontSize:25, }}> <span className={styles.title}>{item.title}</span></div> : <div style={{ marginBottom: 10,fontSize:18, }}> <span className={styles.title}></span><span className={styles.title}>{item.title}</span></div>
                } 
                {
                  renderQuestion(item)
                }
              </div>
            </div>
          })
        }
      </div>
      <div className='tt-text'>Please Note: once this Survey is submitted, your account manger will be followed up and proivded more details to you</div>
      <Button type='primary' style={{ width: 100, marginTop: 20,justifyItems: 'center' }} onClick={() => {
        submit();
       
      }}>Submit</Button>
      
    </div >
  </>
  )
}
