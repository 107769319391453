import "./Careers.css";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Popup from "./Popup";
import { getFirestore,collection, getDocs,deleteDoc,doc,writeBatch } from "firebase/firestore";
import { UserAuth } from "../Login/AuthContext";
import {app} from '../firebase-config'
const db = getFirestore(app);
import {RiArrowDropDownLine, RiArrowDropUpLine} from 'react-icons/ri';
export default function CareerView() {
  const location = useLocation();
  const handleClick = () => setClick(!click);
  const [click, setClick] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const { user, logout } = UserAuth();
  let [list, setlist] = useState([
    
   
  ]);

  let [selectTitleValue, setselectTitleValue] = useState("");
  let [defaultSelectIndex, setdefaultSelectIndex] = useState(-1);
  let [selectLocation] = useState([
    {
      text: "Select Location",

    },
    {
      text: "Waller Texas",
    },
    {
      text: "Whitetown Illinois",
    },
    {
      text: "Novi Michigan",
    },
    {
      text: "Monterry Mexico",
    },
  ]); // select location list


  const open = (inx) => {
    console.log(inx);
    let copy = [...list];
    for (let i = 0; i < copy.length; i++) {
      if (inx === i) {
        console.log(copy[inx].isOpen);
        copy[inx].isOpen = !copy[inx].isOpen;
      }
    }
    setlist(copy);
  };

  useEffect( () => {
    async function fetchData() {
      // You can await here
      const response = await getDocs(collection(db, "users"));
      // ...
      const arr=[]
      response.forEach((doc) => {
        arr.push(doc.data())
        console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
      });
      setlist(arr)
    }
    fetchData();
    setTimeout(async ()=>{
      await deleteDoc(doc(db, "cities", "DC"));
    })
    
    logout();
  }, []);

  const getSelectTitleValue = (e) => {
    setselectTitleValue(e.target.value);
  };

  const getSelect = () => {
    let myselect=document.getElementById("mySelect");
    let index = myselect.selectedIndex;
    console.log(index,'index')
    setdefaultSelectIndex(index)
  }

  const searchFilter = () => {
    console.log(selectTitleValue,defaultSelectIndex);
    if(selectTitleValue!=='' && defaultSelectIndex === -1){
      let copy = [...list]
      copy = copy.filter(item=>{
        return item.jobTitle === selectTitleValue
      })
      setlist(copy)
    }
    if(selectTitleValue ==='' && defaultSelectIndex !== -1){
      let copy = [...list]
      copy = copy.filter(item=>{
        return item.jobLocation === selectLocation[defaultSelectIndex].text
      })
      setlist(copy)
    }
    if(selectTitleValue !=='' && defaultSelectIndex !== -1){
      let copy = [...list]
      copy = copy.filter(item=>{
        return item.jobLocation === selectLocation[defaultSelectIndex].text && item.jobTitle === selectTitleValue
      })
      setlist(copy)
    }
  };

  return (
    <div className="careers-wrapper">
      <span className="careers-title">Careers at Broad-Ocean Motor</span>
      <div className="careers-search-bar">
        {/* <input
          className="careers-input"
          placeholder="Enter Key Word or title"
          onChange={getSelectTitleValue}
        ></input><div>or</div>
        <select id="mySelect" className="careers-input" placeholder="Select Location" onChange={getSelect}>
          {selectLocation.length > 0 &&
            selectLocation.map((item) => {
              return <option value={item.text}>{item.text}</option>;
            })}
        </select>
        <button className="careers-button1" onClick={searchFilter}>
          Search
        </button> */}
        <button className="careers-button1" onClick={() => setButtonPopup(true)} >Apply</button>
        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
        
        </Popup>
      </div>
      <span className="careers-result">{list.length} Result</span>
      <div className="careers-block">
        {list.map((item, index) => {
          return (
            <div className="Hitem" key={Math.random()}>
              <div className="careers-title-bar" onClick={() => {
                    open(index);
                    handleClick();
                  }}>
                    <i className="c-icon">{click ? <RiArrowDropUpLine size='30px' color="white"/> : <RiArrowDropDownLine size='30px' color="white"/>}</i>
                <div className="title-bar-title">{item.jobTitle}</div>
                <div className="title-bar-location">{item.jobLocation}</div>
                
              </div>
              {item.isOpen && (
                <div className='HbottomContent' >
                  <div className="HeachItem">
                    <div className="jd-title">Job Descrition</div>
                    <div className="jd-content">
                     
                        {item.jobDescription.map((item) => {
                          return <div>{item}</div>;
                        })}
                     
                    </div>
                  </div>
                  <div className="HeachItem">
                    <div className="jd-title">Key Duties and Responsibilities</div>
                    <div className="jd-content">
                      <ui>
                        {item.keyDutiesAndRes.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ui>
                    </div>
                  </div>
                  <div className="HeachItem">
                    <div className="jd-title">Minimum Education/Certification Requirements and Experience:</div>
                    <div className="jd-content">
                      <ui>
                        {item.Minimun.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ui>
                    </div>
                  </div>
                  <div className="HeachItem">
                    <div className="jd-title">Preferred Qualifications and Skills:</div>
                    <div className="jd-content">
                      <ui>
                        {item.skill.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ui>
                    </div>
                  </div>
                  <div className="HeachItem">
                    <div className="jd-title">Benefits:</div>
                    <div className="jd-content">
                      <ui>
                        {item.benefit.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ui>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="careers-bottom-block"></div>
    </div>
  );
}
