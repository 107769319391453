import { useState,useEffect } from "react";
import "./Add.css";
import { useNavigate  } from 'react-router-dom'
import { useLocation,Link } from 'react-router-dom'
import { AiOutlineLeft} from "react-icons/ai";
import {app} from '../../firebase-config'
import { getFirestore } from "firebase/firestore";
import { collection, addDoc,writeBatch } from "firebase/firestore";
import { BsXCircleFill } from "react-icons/bs";
import { UserAuth } from "../AuthContext";
const DB = getFirestore(app);
export default function AddView() {
  const navigate = useNavigate ()
  const location = useLocation()
  const { user, logout } = UserAuth();
  let [JobTitle, setJobTitle] = useState("");
  let [Location, setLocation] = useState("");
  let [onevalue, setonevalue] = useState("");
  let [twovalue, settwovalue] = useState("");
  let [threevalue, setthreevalue] = useState("");
  let [fourvalue, setfourvalue] = useState("");
  let [fivevalue, setfivevalue] = useState("");

  let [one, setone] = useState([]);
  let [two, settwo] = useState([]);
  let [three, setthree] = useState([]);
  let [four, setfour] = useState([]);
  let [five, setfive] = useState([]);

  const getValue = (e) => {
    let eId = e.target.id;
    let eValue = e.target.value;
    console.log(eId, eValue);
    if (eId === "JobTitle") {
      setJobTitle(eValue);
    }
    if (eId === "Location") {
      setLocation(eValue);
    }
    if (eId === "one") {
      setonevalue(eValue);
    }
    if (eId === "two") {
      settwovalue(eValue);
    }
    if (eId === "three") {
      setthreevalue(eValue);
    }
    if (eId === "four") {
      setfourvalue(eValue);
    }
    if (eId === "five") {
      setfivevalue(eValue);
    }
  };

  const push = (type) => {
    if (type === "one") {
      let copy = [...one];
      copy.push(onevalue);
      setone(copy);
      setonevalue("");
    }
    if (type === "two") {
      let copy = [...two];
      copy.push(twovalue);
      settwo(copy);
      settwovalue("");
    }
    if (type === "three") {
      let copy = [...three];
      copy.push(threevalue);
      setthree(copy);
      setthreevalue("");
    }
    if (type === "four") {
      let copy = [...four];
      copy.push(fourvalue);
      setfour(copy);
      setfourvalue("");
    }
    if (type === "five") {
      let copy = [...five];
      copy.push(fivevalue);
      setfive(copy);
      setfivevalue("");
    }
  };

  const getData = async () => {
    let data = {
      isOpen: false,
      jobTitle: JobTitle,
      jobLocation: Location,
      jobDescription: one,
      keyDutiesAndRes: two,
      Minimun: three,
      skill: four,
      benefit: five,
    };
    try {
      const docRef = await addDoc(collection(DB, "users"), {
        isOpen: false,
        jobTitle: JobTitle,
        jobLocation: Location,
        jobDescription: one,
        keyDutiesAndRes: two,
        Minimun: three,
        skill: four,
        benefit: five,
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    
    console.log(data, "data");
    setJobTitle("");
    setLocation("");
    setonevalue("");
    settwovalue("");
    setthreevalue("");
    setfourvalue("");
    setfivevalue("");
    setone([]);
    settwo([]);
    setthree([]);
    setfour([]);
    setfive([]);
    setTimeout(()=>{
      navigate('/Delete',{ state: { addItem: data } })
    },500)
  };

  const deleteOneItem = (index, type) => {
    if (type === "one") {
      let copy = [...one];
      copy.splice(index, 1);
      setone(copy);
    }
    if (type === "two") {
      let copy = [...two];
      copy.splice(index, 1);
      settwo(copy);
    }
    if (type === "three") {
      let copy = [...three];
      copy.splice(index, 1);
      setthree(copy);
    }
    if (type === "four") {
      let copy = [...four];
      copy.splice(index, 1);
      setfour(copy);
    }
    if (type === "five") {
      let copy = [...five];
      copy.splice(index, 1);
      setfive(copy);
    }
  };


  useEffect(()=>{
    console.log(location,'localtion')
    if(location.state !== null){
      let res = location.state.editItem
      setJobTitle(res.jobTitle);
      setLocation(res.jobLocation);
      setone(res.jobDescription);
      settwo(res.keyDutiesAndRes);
      setthree(res.Minimun);
      setfour(res.skill);
      setfive(res.benefit);
    }

    const handleBeforeUnload = (event) => {
      // Do something before the page is unloaded, such as displaying a confirmation dialog
      event.preventDefault();
      event.returnValue = '';
    };

    const handleUnload = () => {
      // Do something before the page is unloaded, such as sending data to the server
      logout();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      // Clean up the event listeners when the component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };

  },[])

  return (
    <div className="addwrapper">
      <div className="add-top">
        <Link to='/Account'><span className="add-back"><AiOutlineLeft size='25px'/>  Back</span></Link>

      
        
      </div>
      <div className="add-title"> Add Position</div>
      <div className="add-body">
      
        <div className="left">
          <div className="add-Top">
            <div className="item">
              <span className="add-text">Job Title</span>
              <input
                className="input"
                onChange={getValue}
                id="JobTitle"
                value={JobTitle}
              ></input>
            </div>
            <div className="item">
              <span className="add-text">Location</span>
              <input
                className="input"
                onChange={getValue}
                id="Location"
                value={Location}
              ></input>
            </div>
          </div>
          <div className="add-center">
            <div className="special">
              <span className="add-text">
                Job Description{" "}
                <span>
                  <input
                    placeholder="Add Description"
                    id="one"
                    onChange={getValue}
                   
                  ></input>
                  <button className="hr-button1"
                    onClick={() => {
                      push("one");
                      
                    }}
                  >
                    Add
                  </button>
                </span>
              </span>
              <div className="input specialInput">
                <ol>
                  {one.length > 0 &&
                    one.map((item, index) => {
                      return (
                        <li key={Math.random()}>
                          {item}
                          <span
                            onClick={() => {
                              deleteOneItem(index, "one");
                            }}
                            style={{
                              color: "red",
                              marginLeft: "12px",
                              cursor: "pointer",
                            }}
                          >
                            <BsXCircleFill/>
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>

            <div className="special">
              <span className="add-text">
              Key Duties and Responsibilities{" "}
                <span>
                  <input
                    placeholder="Add Description"
                    id="two"
                    onChange={getValue}
                  ></input>
                  <button className="hr-button1"
                    onClick={() => {
                      push("two");
                    }}
                  >
                    Add
                  </button>
                </span>
              </span>
              <div className="input specialInput">
                <ol>
                  {two.length > 0 &&
                    two.map((item, index) => {
                      return (
                        <li key={Math.random()}>
                          {item}
                          <span
                            onClick={() => {
                              deleteOneItem(index, "two");
                            }}
                            style={{
                              color: "red",
                              marginLeft: "12px",
                              cursor: "pointer",
                            }}
                          >
                            <BsXCircleFill/>
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>

            <div className="special">
              <span className="add-text">
              Minimum Education/Certification Requirements and Experience{" "}
                <span>
                  <input
                    placeholder="Add Description"
                    id="three"
                    onChange={getValue}
                  ></input>
                  <button className="hr-button1"
                    onClick={() => {
                      push("three");
                    }}
                  >
                    Add
                  </button>
                </span>
              </span>
              <div className="input specialInput">
                <ol>
                  {three.length > 0 &&
                    three.map((item, index) => {
                      return (
                        <li key={Math.random()}>
                          {item}
                          <span
                            onClick={() => {
                              deleteOneItem(index, "three");
                            }}
                            style={{
                              color: "red",
                              marginLeft: "12px",
                              cursor: "pointer",
                            }}
                          >
                           <BsXCircleFill/>
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="left">
          <div className="center">
            <div className="special">
              <span className="add-text">
              Preferred Qualifications and Skills{" "}
                <span>
                  <input
                    placeholder="Add Description"
                    id="four"
                    onChange={getValue}
                  ></input>
                  <button className="hr-button1"
                    onClick={() => {
                      push("four");
                    }}
                  >
                    Add
                  </button>
                </span>
              </span>
              <div className="input specialInput">
                <ol>
                  {four.length > 0 &&
                    four.map((item, index) => {
                      return (
                        <li key={Math.random()}>
                          {item}
                          <span
                            onClick={() => {
                              deleteOneItem(index, "four");
                            }}
                            style={{
                              color: "red",
                              marginLeft: "12px",
                              cursor: "pointer",
                            }}
                          >
                            <BsXCircleFill/>
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          </div>

          <div className="center">
            <div className="special">
              <span className="add-text">
                Benefit{" "}
                <span>
                  <input
                    placeholder="Add Description"
                    id="five"
                    onChange={getValue}
                  ></input>
                  <button className="hr-button1"
                    onClick={() => {
                      push("five");
                    }}
                  >
                    Add
                  </button>
                </span>
              </span>
              <div className="input specialInput">
                <ol>
                  {five.length > 0 &&
                    five.map((item, index) => {
                      return (
                        <li key={Math.random()}>
                          {item}
                          <span
                            onClick={() => {
                              deleteOneItem(index, "five");
                            }}
                            style={{
                              color: "red",
                              marginLeft: "12px",
                              cursor: "pointer",
                            }}
                          >
                            <BsXCircleFill/>
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
            <button className="hr-button" onClick={getData}>
        Post
      </button>
          </div>
        
        </div>
      </div>
      <div className="add-bottom-block"></div>
    </div>
  );
}