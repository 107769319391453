import { Button, Checkbox, Input, Radio, Rate, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./index.module.css";
import * as api from '../firebase/firestore';
import { async } from '@firebase/util';
import moment from "moment";
import ExportJsonExcel from "js-export-excel";
export default function SearchList() {

  const [list, setList] = useState([]);

  const [questions, setQuestions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // api.addQuestion({
    //   "title": "Which score you get!",
    //   "type": 3
    // });
    search();
  }, [])


  const search = async () => {
    let newList = await api.queryPaperResult();
    console.log("newList", newList)

    let questions = await api.queryQuestion();
    questions.forEach(item => {
      let c = 0;
      if (item.type === 3) {
        item.options = [

        ];
        for (let i = 0; i < 5; i++) {
          item.options.push({
            "title": "",
            v: i + 1
          });
        }
      }
      newList.forEach(q => {

        let timu = q.list.find(it => it.id === item.id);
        console.log("timu", timu, q.list, questions);
        if (timu && (timu.type === 1 || timu.type === 3 || timu.type === 4 )) {
          item.options.forEach(option => {
            if (!(timu.value instanceof Array)) {
              timu.value = [timu.value];
            }
            if (timu.value.includes(option.v)) {
              if (!option.count) {
                option.count = 1;
              } else {
                option.count++;
              }
            }

          });
        }

      });
    });

    setQuestions(questions);

    setList(newList);
  }

  const submit = async () => {
    let res = await api.submitPaperResult({
      time: moment().format(),
      list: list
    });
    console.log("res", res)
  }

  const renderQuestion = (item) => {
    if (item.type === 1 || item.type === 3 || item.type === 4) {
      return <div>
        <Checkbox.Group onChange={(e) => {
          console.log("e", e);
          item.value = e;
        }}>
          {item.options.map((option) => {
            return <div key={option.v} style={{ marginBottom: 10 }}>
              <span style={{ marginRight: 2, marginLeft: 10 }}>{option.v}:</span>
              <span>{option.title}</span>
              <span style={{ "backgroundColor": "#9898dd", color: "#fff", padding: "5px 10px", marginLeft: 20 }}>{option.count || 0} Selected</span>
            </div>
          })}
        </Checkbox.Group>
      </div>;
    }
  }

  const exportExcel = async () => {
    let questions = await api.queryQuestion();
    let res = [];
    list.forEach(q => {
      let row = {};
      questions.forEach(item => {
        let qes = q.list.find(l => l.id === item.id);
        if (qes) {
          row[qes.title] = qes.value;
        }
      });
      res.push(row);
    });


    let sheetFilter = questions.map(item => item.title);
    let option = {};
    option.fileName = 'result';

    option.datas = [
      {
        sheetData: res,
        sheetName: 'result',
        sheetFilter: sheetFilter,
        sheetHeader: questions.map(item => item.title),
        columnWidths: [10, 10, 10, 10]
      }
    ];
    console.log(" option.datas", option.datas);
    var toExcel = new ExportJsonExcel(option); 
    toExcel.saveExcel(); 

  }

  return (<>
    <div className={styles.searchList} >
      <Button type="primary" onClick={() => {
        exportExcel();
      }}>export excel</Button>
      <div className={styles.listItems}>
        {
          questions.map((item, index) => {
            return (item.type === 1  || item.type === 4) ? <div key={item.id} className={styles.listItem}>
              <div className={styles.right} >
                <div style={{ marginBottom: 10 }}> <span className={styles.title}></span><span className={styles.title}>{item.title}</span></div>
                {
                  renderQuestion(item)
                }
              </div>
            </div> : <></>
          })
        }
      </div>

      <h1 style={{ marginTop: 40 }}>{list.length} total record</h1>
      <div className={styles.listItems}>
        {
          list.map((item, index) => {
            return <div key={item.id} className={styles.listItem}>
              <Link to={"/detail/" + item.id} >
                submit at {item.time}
              </Link>
            </div>
          })
        }
      </div>

    </div >
  </>
  )
}