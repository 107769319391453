import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyBN5BHiFOttkVgl3F_Ue-NWBwUzmXJmCC8",
  authDomain: "bomnaweb-88ab3.firebaseapp.com",
  projectId: "bomnaweb-88ab3",
  storageBucket: "bomnaweb-88ab3.appspot.com",
  messagingSenderId: "729878209672",
  appId: "1:729878209672:web:e9baa6c25d4b634a646b3d",
  measurementId: "G-4X2CGDH7DQ"
};


export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const database = getDatabase(app);