import React from "react";

import './Michigan.css'


class Subsheet1 extends React.Component{
    state = {
        showsubsection1: true,
        showsubsection2: false,
        showsubsection3: false,
        showsubsection4: false,
    }
    

     render(){
        

        const {showsubsection1,showsubsection2,showsubsection3,showsubsection4} = this.state;
      
         return(
               <div className="subsheetwrapper">
                   <div className="subsheet-section-title">Hybrid and Electrical E-Drive Systems</div>
                   <div className="subsheet-menu-inner">
                    
                    <div className="ssm-block">
                        <div className="subsheet-section-menu">
                        
                        <div className="ss-buttom" onClick={() => this.setState({
                            showsubsection1: false,
                            showsubsection2: false,
                            showsubsection3: false,
                            showsubsection4: true,
                        
                        })} >
                        
                        <div className="ss-title">3-5 Year Objectives</div>
                        
                        </div>

                    </div>

                    <div className="subsheet-section-menu">
                        
                            <div className="ss-buttom" onClick={() => this.setState({
                                showsubsection1: false,
                                showsubsection2: false,
                                showsubsection3: true,
                                showsubsection4: false,
                            
                            })} >
                            
                            <div className="ss-title">Projects & Processes</div>
                            
                            </div>

                        </div>
                        </div>
                   




                        <div className="ssm-block"> 
                        <div className="subsheet-section-menu">
                            
                            <div className="ss-buttom" onClick={() => this.setState({
                                showsubsection1: false,
                                showsubsection2: true,
                                showsubsection3: false,
                                showsubsection4: false,
                            
                            })} >
                            
                            <div className="ss-title">Why BOT Is Unique</div>
                            
                            </div>

                        </div>

                   <div className="subsheet-section-menu">
                            
                            <div className="ss-buttom" onClick={() => this.setState({
                                showsubsection1: true,
                                showsubsection2: false,
                                showsubsection3: false,
                                showsubsection4: false,
                                
                            
                            })} >
                            
                            <div className="ss-title">What we do</div>
                            
                            </div>

                        </div>
                        </div>
                       

                       

                        
                        
                      




                   </div>
                   <div className="subsheet-seaction">
                        {showsubsection1 && (<Subsection1/>)}
                        {showsubsection2 && (<Subsection2/>)}
                        {showsubsection3 && (<Subsection3/>)}
                        {showsubsection4 && (<Subsection4/>)}
                        </div>
               </div>
         );
     }

}

const Subsection1 = () =>(
<div className="subsheet-content">
<div className="subsheet-content-inner">
<span className="span-title">What We Do</span><br></br>
We design various electric drive products for EV and HEV applications. The power rating of our electric drive product ranges from a few kW to a few hundreds of kW. The torque rating ranges from a few Nm to a few thousands of Nm. Typical applications of our low rating products include Belt-driven Starter Generator (BSG) for passenger cars and Motor Generator Unit (MGU) for commercial vehicles. Our higher power/torque products are found in public buses and delivery trucks.<br></br>
50kW Motor - 50kW Inverter<br></br>
100kW Motor - 100kW Inverter<br></br>
2800Nm Motor - 2300Nm Motor<br></br>
430Nm Motor - Scalable Inverter<br></br>
48V 40Nm-12kW BSG (water-cooled)

        </div>

</div>

) 
const Subsection2 = () =>(
    <div className="subsheet-content">
        <div className="subsheet-content-inner">
            <span className="span-title">Why BOT Is Unique</span><br></br>
            We provide total solutions at the sub-system level for OEMs. Our sub-systems cover the complete electric drive systems including electric motors, inverter HW and inverter SW. Our technical expertise are specialized in motor EM design, motor packaging design, power electronics design and packaging, electronics design, SW and motor control. All above technical specialties are carried out in house.<br></br>
Once the design is completed, it is released to our manufacturing facility, at Zhongshan, China, where we produce millions of motors and motor controllers annually. The prototype of our design will be built at the plant in China and shipped back to Novi, US where BOT is located. At BOT, we have full scale test facilities including one 260kW, 500Nm, 16,000rpm dyno setup and one 350kW, 2800Nm, 12,000rpm dyno setup. The prototype will be fully tested at BOT and complete the final proof out of A sample design. Starting from B samples, the design will be transferred to our manufacturing facility in China where all volume production processes will be strictly followed.


        </div>
        
    
    </div>
    
    ) 
    const Subsection3 = () =>(
        <div className="subsheet-content">
         <div className="subsheet-content-inner">
         <span className="span-title">Projects & Processes</span><br></br>
         Based on a number of customer requirements, we are currently working on a 48V BSG commercial vehicle applications. The following are some of the main design parameters.
        <li>Motor type:	IPM</li>
        <li>Packaging:	motor-inverter integrated</li>
        <li>Cranking torque:240Nm</li>
        <li>Torque boost:130Nm</li>
        <li>Power boost:25kW</li>
        <li>Speed range:12,000rpm</li>
        <li>Cooling method:	water/glycol</li>
        </div>
       
        </div>
        
        ) 
        const Subsection4 = () =>(
            <div className="subsheet-content">
             <div className="subsheet-content-inner">
             <span className="span-title">3-5 Year Objectives</span><br></br>
             Packaging, packaging, it’s all about packaging! We are striving for the design of next generation power electronics packaging with considerations of performance, power density, EMC, manufacturability, reliability, and cost.


        </div>
            
            </div>
            
            ) 


export default Subsheet1;