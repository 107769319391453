import React from "react";

import './About.css';
import img1 from './Aboutimg/uimg1.webp'
import img2 from './Aboutimg/uimg2.webp'
import { UserAuth } from "../Login/AuthContext";
function University(){
    const { user, logout } = UserAuth();
    useEffect(() => {
        logout();
   }, [])
 
return (
 <div className="unwrapper">
     <div className="un-inner">
     <div className="un-title">University Outreach Opportunities</div>
    <div className="un-text">Broad-Ocean Motor is a leader in developing electric motors for HVAC and automotives and recruits students from University of Michigan and Wayne State University that are interested in participating in advanced engineering research projects. Our hands-on industry experience will provide a strong foundation as you embark on your professional journey. We are looking for talented, driven and energetic people who are passionate about finding the most efficient solutions for our customers and caring for the sustainability of our planet. We take pride in recruiting top talent to further our corporate goals.</div>
    
    <div className="un-img">
        <img className="un-i" src={img1}/>
        <img className="un-i" src={img2}/>
    </div>
    {/* <div className="un-text">For more information please contact<a href="https://engineering.wayne.edu/profile/bb4629">Caisheng Wang</a> or <a href="https://sites.google.com/site/wangmengqi">Mengqi (Maggie) Wang</a> </div> */}
    </div>
 </div>
  





);




}
export default University;