import React,{useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import './Layout.css';
import footerlogo from './Layoutimage/footerlogo.webp'
import facebook from './Layoutimage/facebook.webp'
import linkedin from './Layoutimage/linkedin.webp'
function Footer(){
   const scrolltop = () =>{
       window.scrollTo({top:0});
   };
   
   const today = new Date();
return(
    <div className="footerwrapper">
        
 <div className="footer-top">
    {/* <div className="top-left">
        <img src={footerlogo}/>
        
    </div> */}

    <div className="top-right">
    <div className="footer-block">
        <div className="right-con">
        <div className="info1">Navigation</div>
        <div className="right-li">
            <li className="l1"><NavLink to='/Home' onClick={scrolltop}>Home</NavLink></li>
            <li className="l1"><NavLink to='/About' onClick={scrolltop}>About Us</NavLink></li>
            <li className="l1"><NavLink to='/Careers' onClick={scrolltop}>Careers</NavLink></li>
            <li className="l1"><NavLink to='/News' onClick={scrolltop}>News</NavLink></li>
            <li className="l1"><NavLink to='/Contact' onClick={scrolltop}>Contact Us</NavLink></li>
        </div>
        </div>
        <div className="right-con">
        <div className="info1">Products</div>
        <div className="right-li">
            <li className="l1"><NavLink to='/Hvac' onClick={scrolltop}>HVAC Application</NavLink></li>
            <li className="l1"><NavLink to='/EV' onClick={scrolltop}>EV Motors</NavLink></li>
            <li className="l1"><NavLink to='/' onClick={scrolltop}>Fuel Cells</NavLink></li>
            {/* <li className="l1"><NavLink to='/signin' onClick={scrolltop}>Survey</NavLink></li> */}
        </div>
        </div>
        
        
        <div className="right-con">
        <div className="info1">Locations</div>
        <div className="right-li">
            <li className="l1"><NavLink to='/Mic' onClick={scrolltop}>Michigan</NavLink></li>
            <li className="l1"><NavLink to='/Hou' onClick={scrolltop}>Texas</NavLink></li>
            <li className="l1"><NavLink to='/Ind' onClick={scrolltop}>Indiana</NavLink></li>
            <li className="l1"><NavLink to='/Mx' onClick={scrolltop}>Mexico</NavLink></li>
        </div>


        </div>
        </div>
        <div className="footer-block">
        <div className="right-con">
        <div className="info1">Other Entities</div>
        <div className="right-li">
            <li className="l1"><a href="http://www.broad-ocean.com/broad-ocean-cn/index.html" >Zhongshan Broad-Ocean Motor</a></li>
            <li className="l1"><a href="https://www.prestolite.com/north-america/">Prestolite Electric NA</a></li>
            <li className="l1"><a href="https://www.prestolite-eu.com/">Prestolite Electric EU</a></li>
            <li className="l1"><a href="http://www.prestolite-bj.com/">Prestolite Electric CN(Beijing)</a></li>
            
        </div>


        </div>


        <div className="right-con">
        <div className="info1">&nbsp; </div>
        <div className="right-li">
           
            
            <li className="l1"><a href="https://www.chinaedrive.com/">Shanghai Edrive</a></li>
            <li className="l1"><a href="https://www.whgnr.com/">Wuhu GNR</a></li>
        </div>


        </div>

        </div>
    




        

                
            </div>
            
</div>
<div className="social-media">
<a href="https://www.linkedin.com/company/broad-ocean-technologies-llc"><img className="linkedin-icon" src={linkedin}/></a>
<a href="https://m.facebook.com/Broad-Ocean-Motor-Group-107127298270212/?ref=page_internal"><img className="fb-icon" src={facebook}/></a>

</div>
<div className="footer-bottom">
                 <div className='tp'>Copyright © {today.getFullYear()} Broad-Ocean Technologies</div>  
                {/* <div className="tp">Terms of Use</div>
                <div className="tp">Privacy Policy</div> */}
            
                </div>   
</div>

    





);


}

export default Footer;