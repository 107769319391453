import img1 from './s4img/s4img1.webp'
import img2 from './s4img/s4img2.webp'
import img3 from './s4img/s4img3.webp'
import img4 from './s4img/s4img4.webp'
import img5 from './s4img/s4img5.webp'
import img6 from './s4img/s4img6.webp'
import img7 from './s4img/s4img7.webp'
import img8 from './s4img/s4img8.webp'
import img9 from './s4img/s4img9.webp'



export const sliderData = [
    {
      image: img1,
     
     
    },
    {
      image: img2,
     
     
    },
    {
      image: img3,
     
     
    },
    {
      image: img4,
     
     
    },
    {
      image: img5,
     
     
    },
    {
      image: img6,
     
     
    },
    {
      image: img7,
     
     
    },
    {
      image: img8,
     
     
    },
    {
      image: img9,
     
     
    },
  ];