import { listAll, ref, getDownloadURL, getMetadata, deleteObject} from "firebase/storage";
import { useState, useReducer, useRef } from "react";
import { useParams } from "react-router-dom";
import { Modal, message } from "antd";
import { CircularProgress } from "@mui/material";
import { storage } from "../../firebase-config";
import { UserAuth } from "../../Login/AuthContext";
import FileFolder from "./FileFolder";
import FileCard from '../UI/Card';
import NoFilesDisplay from "./NoFilesDisplay";
import DatabaseController from "./DatabaseController";
import downloadIcon from "../Images/downloadIcon.png";
import deleteIcon from "../Images/deleteIcon.png";
import downArrow from "../Images/downArrow.png";
import styles from './FileLinks.module.css';

const fileDataReducer = (currentState, action) => {
    switch (action.type) {
        case 'set-deleting':
            return (
                {...currentState, fileCollection: currentState.fileCollection.map(fileRef => {
                    if (fileRef[0].name === action.value[0].name) {
                        return [fileRef[0], true];
                    }
                    return fileRef;
                })}
            )
        case 'remove-url':
            return (
                {...currentState, urlCollection: currentState.urlCollection.filter(
                    currentUrl => currentUrl !== action.value)
                }
            );
        case 'remove-file':
            return (
                {...currentState, fileCollection: currentState.fileCollection.filter(
                    currentFile => currentFile[0].fullPath !== action.value[0].fullPath)
                }
            );
        case 'remove-metadata':
            return (
                {...currentState, metadataCollection: currentState.metadataCollection.filter(
                    currentMetadata => currentMetadata !== action.value)
                }
            )
        case 'populate-files':
            return {...currentState, fileCollection: action.value.items.map(file => [file, false])};
        case 'populate-urls':
            return {...currentState, urlCollection: action.value};
        case 'populate-metadata':
            return {...currentState, metadataCollection: action.value};
        default:
            return {urlCollection: [], fileCollection: [], metadataCollection: []};

    }
}

export default function FileLinks(props) {
    const [dataRetrievalAttempt, setRetrievalAttempt] = props.DataRetrieval;

    const [fileData, dispatchFileData] = useReducer(
        fileDataReducer, {urlCollection: [], fileCollection: [], metadataCollection: []});

    const fileToBeDeletedRef = useRef(null);
    const linkDisplayRender = useRef([]);
    const displayRenderLock = useRef(false);

    const [uploadDisplayRender, setUploadDisplayRender] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const { batchToken } = useParams();

    const fileBatchDB = new DatabaseController();

    const storageRef = ref(storage);
    const loadingMsg = "Loading Resources - Please Try Again!"
    const displayData = new Map();

    let uid;
    if (UserAuth().user) {
        uid = UserAuth().user.uid;
    }

    const showModal = (file, url, metadata) => {
        if ((fileData.urlCollection.filter(urlRef => urlRef === url).length === 0) ||
            (!dataRetrievalAttempt)) {
            message.warning(loadingMsg);
        }
        else if (file[1]) {
            message.warning(`Please wait for ${file[0].name} to be deleted!`);
        }
        else {
            fileToBeDeletedRef.current = [file, url, metadata];
            setIsOpen(true);
        }
    }

    const handleModal = (cancel) => {
        if (!cancel) {
            deleteHandler(fileToBeDeletedRef.current);
        }
        else {
            fileToBeDeletedRef.current = null;
        }
        setIsOpen(false);
    }

    async function downloadHandler(downloadURL, fileName) {
        if (fileData.urlCollection.filter(url => url === downloadURL).length !== 0) {
            const res = await fetch(downloadURL).catch(
                () => {
                    message.error("Failed to fetch download data, please refresh the page!");
                    return null;
                });
            if (!res) {
                return;
            }

            const blob = await res.blob();
            const url = URL.createObjectURL(blob);
            const downloadAnchor = document.createElement("a");
            downloadAnchor.href = url;
            downloadAnchor.download = fileName;
            downloadAnchor.click();
            downloadAnchor.remove();
        }
        else {
            message.warning(loadingMsg);
        }
    }

    async function deleteHandler(fileInfo) {
        dispatchFileData({type: 'set-deleting', value: fileInfo[0]});

        const res = await deleteObject(fileInfo[0][0]).catch(() => {
            message.error("Something went wrong, try refreshing the page!");
            return 1;
        });
        if (res) {
            return;
        }

        message.success(`${fileInfo[0][0].name} was deleted successfully!`)

        await fileBatchDB.DeleteFiles([fileInfo[0][0].name]);

        dispatchFileData({type: 'remove-file', value: fileInfo[0]});
        dispatchFileData({type: 'remove-url', value: fileInfo[1]});
        dispatchFileData({type: 'remove-metadata', value: fileInfo[2]});

        fileToBeDeletedRef.current = null;
        displayRenderLock.current = false;
    }

    async function deleteFolderHandler(fileNames) {
        for (const fileName of fileNames) {
            const fileInfo = [displayData.get(fileName).thisFile, 
                displayData.get(fileName).url, displayData.get(fileName).metadata];
            await deleteHandler(fileInfo).catch(() => {});
        }
    }
    
    async function getData(storageRef) {
        let files = await listAll(storageRef).catch(() => {});

        const authorizedFiles = [];

        if (props.StandAlone) {
            for (const file of files.items) {
                const authorized = await fileBatchDB.InBatch(
                    fileBatchDB.CreateKeyName(file.name), batchToken);
                if (authorized) {
                    authorizedFiles.push(file);
                }
            } 
            files.items = authorizedFiles;
        }
        else {
            const isAdmin = await fileBatchDB.IsAdmin(uid);
            
            if (!isAdmin) {
                for (const file of files.items) {
                    const owner = await fileBatchDB.GetUser(file.name);
                    if (owner === uid) {
                        authorizedFiles.push(file);
                    }
                }
                files.items = authorizedFiles;
            }
        }

        const urlPromises = files.items.map(fileRef => getDownloadURL(fileRef).catch(() => {}));
        const metadataPromises = files.items.map(fileRef => getMetadata(fileRef).catch(() => {}));
        
        const metadata = await Promise.all(metadataPromises).catch(() => {});

        const urls = await Promise.all(urlPromises).catch(() => {});

        dispatchFileData({type: 'populate-metadata', value: metadata});
        dispatchFileData({type: 'populate-urls', value: urls})
        dispatchFileData({type: 'populate-files', value: files});

        setRetrievalAttempt(true); // Lock the data retrieval function call on re-render
        
        displayRenderLock.current = false;
    }

    function fileDisplayHelper(data) {
        const displayMap = [];
        for (const [name, info] of data) {
            displayMap.push(
                <FileCard key={name}>
                    <p className={styles['file-name']}>{name}</p>
                    <span className={styles.spacer}/>
                    {info.linkButton}{!props.StandAlone && info.deleteButton}
                </FileCard> )
        }
        return displayMap;
    }

    async function generateFilesDisplay(displayData) {
        if (props.StandAlone) {
            linkDisplayRender.current = fileDisplayHelper(displayData);
        }
        else {
            const folders = {}, updatedDisplayRender = [];
            for (const [name, info] of displayData) {
                const folder = await fileBatchDB.GetBatch(name);
                
                if (!folders[folder]) {
                    folders[folder] = [[name, info]];
                }
                else {
                    folders[folder].push([name, info]);
                }
            }
            
            for (const folder in folders) {
                updatedDisplayRender.push(
                <FileFolder
                    Token={folder}
                    Created={folders[folder][0][1].created}
                    Files={fileDisplayHelper(folders[folder])}
                    Delete={deleteFolderHandler} />);
            }
            setUploadDisplayRender(updatedDisplayRender);
            
            displayRenderLock.current = true;
        }
    }

    if (!dataRetrievalAttempt) {
        getData(storageRef);
    }

    for (const index in fileData.fileCollection) {
        let fileSize = 'N/A', created = 'Fetching Data';
        if (fileData.metadataCollection) {
            if (fileData.metadataCollection.length) {
                if (fileData.metadataCollection[index]) {
                    fileSize = fileData.metadataCollection[index].size;
                    created = fileData.metadataCollection[index].updated.slice(0, 10);
                    
                }
            }
        }

        displayData.set(fileData.fileCollection[index][0].name, // name of the file

        {
            // File creation date
            created: created,

            // File info
            thisFile: fileData.fileCollection[index],

            // Url info
            url: fileData.urlCollection[index],

            // Metadata info
            metadata: fileData.metadataCollection[index],

            // Download button
            linkButton:
                <button className={styles.Button} 
title={`File Size: ${fileSize} Bytes
Last Modified: ${created}`}
                onClick={() => downloadHandler(fileData.urlCollection[index], 
                    fileData.fileCollection[index][0].name)}
                ><img src={downloadIcon} alt="downloadIcon"/></button>,

            // Render delete button only if on the administrator page
            ...!props.StandAlone && {deleteButton:
                <button className={styles.Button}
                title={`Delete ${fileData.fileCollection[index][0].name}`}
                onClick={() => showModal(fileData.fileCollection[index], 
                    fileData.urlCollection[index], 
                    fileData.metadataCollection[index])}
                ><img src={deleteIcon} alt="deleteIcon"/></button>
            
            }
        });
    }

    if (displayData.size) {
        if (!displayRenderLock.current) {
            generateFilesDisplay(displayData);
        }
    }

    return (
        <div className={props.StandAlone && styles['link-list-wrapper']}>
            <Modal
                visible={isOpen}
                onOk={() => handleModal(false)}
                onCancel={() => handleModal(true)}
                // Substitute for creating a react-dom portal
                getContainer={document.getElementById('modal-root')}
                >
                    <h2 className={styles['modal-hr']}>Delete file from Cloud Storage</h2>
                    <hr />
                        <p className={styles['delete-text']}>
                            Are you sure you want to delete {fileToBeDeletedRef.current ?
                                `"${fileToBeDeletedRef.current[0][0].name}"` : 'this file?'}?
                        </p>
                    <hr />
            </Modal>
            <div className={styles['header-and-icon']}><h2 className={props.StandAlone && styles['link-list-header']}>
                {props.HeaderTxt}</h2>
                <img src={downArrow} alt="downArrow.png" className={`${styles['down-arrow']} 
                ${props.StandAlone ? styles['arrow-spacer-standalone'] : styles['arrow-spacer-integrated']}`} />
            </div>
            {dataRetrievalAttempt ? (
                displayData.size ? (
                    props.StandAlone ? linkDisplayRender.current : uploadDisplayRender) : 
                    <NoFilesDisplay StandAlone={props.StandAlone} />) : <div><CircularProgress
                              color="inherit"
                              className={styles['loading-spinner']} />
                              <p className={styles['spinner-text']}>Loading Files</p></div>}
        </div>
    );
}