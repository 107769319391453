import Delay from 'react-delay';
import styles from './NoFilesDisplay.module.css';

const NoFilesDisplay = props => {
    return (
        <Delay wait={500}>
            <p className={styles['no-files-text']}>
                No files found {
                !props.StandAlone ? 
                "- empty storage" : "- empty storage or invalid token"}
            </p>
        </Delay>
    );
}

export default NoFilesDisplay;