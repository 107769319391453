import React, { useEffect,useRef, useState}from "react";
import '../HVAC/Product.css';
import Evsheet from './EVsheet'
import { Link} from "react-router-dom";
import file1 from '../Broad-Ocean Motor Product Catalogues.pdf';
import { UserAuth } from "../../Login/AuthContext";
function EV(){
  const { user, logout } = UserAuth();
  useEffect(() => {
    logout();
}, [])

return (

  <div className="hvacwrapper">
    <div className="hvac-title">Product Catalog--EV Motors</div>
    <Evsheet/>
   
  <button className="hvac-download"> <Link to={file1} target="_blank" download>Download the whole Broad-Ocean Motor Products Catalog </Link></button>
  </div>





);




}
export default EV;