import React from "react";

import './Michigan.css'
import img2 from './micimg/micimg2.webp'
import img3 from './micimg/micimg3.webp'
import img4 from './micimg/micimg4.webp'
import Subsheet from './Subsheet'
import Subsheet1 from './Subsheet1'
import Subsheet2 from './Subsheet2'
class Worksheet extends React.Component{
    state = {
        showsection1: true,
        showsection2: false,
        showsection3: false,
        color: '#2666CF',
    }
    

     render(){

        const {showsection1,showsection2,showsection3} = this.state;
        const {color} = this.state;
         return(
                <div className="worksheetwrapper">
                    <div className="worksheet-top">
                        <div className="worksheet-block">
                        <div className="section-menu">
                        <img className="worksheet-img" src={img2}  />
                            <div className="sm-buttom" onClick={() => this.setState({
                                showsection1: true,
                                showsection2: false,
                                showsection3: false,
                                color: 'black',
                            
                            })} style={{ color:'#2666CF'}}>
                            
                            <div className="sm-title">Powertrain Systems</div>
                            
                            </div>

                        </div>
                        <div className="section-menu">
                        <img className="worksheet-img"  src={img3}/>
                            <div className="sm-buttom" onClick={() => this.setState({
                                showsection1: false,
                                showsection2: true,
                                showsection3: false,
                            
                            })}>
                            <div className="sm-title" >Hybrid and Electrical <br></br>E-Drive Systems</div>
                            </div>

                        </div>
                        </div>
                        <div className="section-menu">
                        <img  className="worksheet-img" src={img4}/>
                            <div className="sm-buttom" onClick={() => this.setState({
                                showsection1: false,
                                showsection2: false,
                                showsection3: true,
                            
                            })}>
                            <div className="sm-title">Software and Controls</div>
                            </div>

                        </div>
                        </div>

                        <div className="sectionwrapper">
                        {showsection1 && (<Section1/>)}
                        {showsection2 && (<Section2/>)}
                        {showsection3 && (<Section3/>)}
                        </div>

                </div>
         );
     }

}

const Section1 = () =>(
<div className="section-inner">

        <Subsheet/>

</div>

) 
const Section2 = () =>(
    <div className="section-inner">
    
    <Subsheet1/>
    
    </div>
    
    ) 
    const Section3 = () =>(
        <div className="section-inner">
        
        <Subsheet2/>
        
        </div>
        
        ) 


export default Worksheet;