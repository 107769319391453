import React from "react";

import './Newspage.css';
import img from '../newsimg/newsimg1.webp'
function Newsone(){


return (
 
  <div className="newspagewrapper">
      <div className="newspage-inner">
        <div className="newspage-title">Ballard Power Systems today announced that a $28.3 million strategic equity investment made by Zhongshan Broad-Ocean Motor Co., Ltd. has closed.</div>
        <div className="newspage-date">January 24, 2020</div>
        <div className="newspage-img"><img className="np-img" src={img} /></div>
        <div className="newspage-text">The Broad-Ocean investment, initially announced in a press release on July 26, 2016, has been made through a subscription and purchase of 17,250,000 common shares issued from treasury at a price per share of US$1.64 (based on a 20-day volume weighted average price calculation). The investment represents approximately 9.9% of Ballard’s outstanding common shares following the transaction. The Company intends to use the proceeds from the financing for general corporate purposes, including potential funding of future acquisitions or investments in complementary businesses, products or technologies.</div>
        <div className="newspage-text">Broad-Ocean and Ballard have also entered into an Investor Rights Agreement under which Broad-Ocean has agreed to a two-year hold period on the 17,250,000 Ballard common shares that it has purchased in the financing; has provided Ballard with a right of first refusal to sell Broad-Ocean additional treasury shares if Broad-Ocean wishes to increase its ownership position up to 20%; and has agreed to a two-year “standstill” under which it will not purchase more than 19.9% of Ballard’s outstanding common shares without receiving Ballard board approval. Ballard has granted Broad-Ocean anti-dilution rights to maintain its 9.9% ownership interest. Finally, Broad-Ocean has no special right to appoint nominees to Ballard’s board of directors.</div>
        <div className="newspage-text">In addition to the equity investment, a strategic collaboration framework agreement between Ballard, Broad-Ocean and Guangdong Nation Synergy Hydrogen Power Technology Co. Ltd. was also signed on July 26, 2016, encompassing a number of collaboration activities conducted on commercial terms in China, including:<br></br>
• market development activities and product development for hydrogen fuel cell vehicles, including buses and commercial vehicles;<br></br>
• potential license and local assembly of Ballard fuel cell modules by Broad-Ocean in selected Chinese cities;<br></br>
• integration of Ballard fuel cell modules with Broad-Ocean EV drive systems to provide customers with turnkey fuel cell engines; and<br></br>
• leveraging of Broad-Ocean’s global operations and supply chain infrastructure to lower the cost of Ballard fuel cell engines and the
cost of integration with vehicle drivetrains.</div>



      </div>




  </div>





);




}
export default Newsone;