import React ,{ useEffect }from 'react';
import './Home.css';
import Section1 from './Section1/Section1'
import Section2 from './Section2/Scetion2'
import Section3 from './Section3/Section3'
import Section4 from './Section4/Section4'
import Section5 from './Section5/Section5'
import { UserAuth } from "../Login/AuthContext";






function Home(){

  const { user, logout } = UserAuth();


  
// useEffect(() => {
//   const handleBeforeUnload = (event) => {
//     // Do something before the page is unloaded, such as displaying a confirmation dialog
//     event.preventDefault();
//     event.returnValue = '';
//   };

//   const handleUnload = () => {
//     // Do something before the page is unloaded, such as sending data to the server
//     logout();
//   };

//   window.addEventListener('beforeunload', handleBeforeUnload);
//   window.addEventListener('unload', handleUnload);

//   return () => {
//     // Clean up the event listeners when the component is unmounted
//     window.removeEventListener('beforeunload', handleBeforeUnload);
//     window.removeEventListener('unload', handleUnload);
//   };
// },[]);
useEffect(() => {
  logout();
}, [])
return (
  <div>
  <Section5/>
  {/* <Section1/> */}
  <Section2/>
  {/* <Section3/> */}
  <Section4/>
{/* <div className="s4-b"></div> */}
  </div>
  





);




}
export default Home;