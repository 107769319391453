import React from "react";

import './Michigan.css'

import img6 from './micimg/micimg6.webp'

class Subsheet2 extends React.Component{
    state = {
        showsubsection1: true,
        showsubsection2: false,
        showsubsection3: false,
        showsubsection4: false,
    }
    

     render(){

        const {showsubsection1,showsubsection2,showsubsection3,showsubsection4} = this.state;
        const {color} = this.state;
         return(
               <div className="subsheetwrapper">
                   <div className="subsheet-section-title">Software and Controls</div>
                   <div className="subsheet-menu-inner">
                    
                   <div className="ssm-block"><div className="subsheet-section-menu">
                        
                        <div className="ss-buttom" onClick={() => this.setState({
                            showsubsection1: false,
                            showsubsection2: false,
                            showsubsection3: false,
                            showsubsection4: true,
                        
                        })} >
                        
                        <div className="ss-title">3-5 Year Objectives</div>
                        
                        </div>

                    </div>

                    <div className="subsheet-section-menu">
                        
                            <div className="ss-buttom" onClick={() => this.setState({
                                showsubsection1: false,
                                showsubsection2: false,
                                showsubsection3: true,
                                showsubsection4: false,
                            
                            })} >
                            
                            <div className="ss-title">Projects & Processes</div>
                            
                            </div>

                        </div>
                        </div>
                   




                        <div className="ssm-block"> 
                        <div className="subsheet-section-menu">
                            
                            <div className="ss-buttom" onClick={() => this.setState({
                                showsubsection1: false,
                                showsubsection2: true,
                                showsubsection3: false,
                                showsubsection4: false,
                            
                            })} >
                            
                            <div className="ss-title">Why BOT Is Unique</div>
                            
                            </div>

                        </div>

                   <div className="subsheet-section-menu">
                            
                            <div className="ss-buttom" onClick={() => this.setState({
                                showsubsection1: true,
                                showsubsection2: false,
                                showsubsection3: false,
                                showsubsection4: false,
                                
                            
                            })} >
                            
                            <div className="ss-title">What we do</div>
                            
                            </div>

                        </div>
                        </div>
                        

                        
                        
                      




                   </div>
                   <div className="subsheet-seaction">
                       
                        {showsubsection1 && (<Subsection1/>)}
                        {showsubsection2 && (<Subsection2/>)}
                        {showsubsection3 && (<Subsection3/>)}
                        {showsubsection4 && (<Subsection4/>)}
                        </div>
               </div>
         );
     }

}

const Subsection1 = () =>(
<div className="subsheet-content">
<div className="subsheet-content-inner">
<span className="span-title">What We Do</span><br></br>The Software & Control Team develops control strategies for hybrid electrical vehicle systems and motor drive subsystems. The team performs Modeling/Simulation, Auto-Code Generation, Calibration and Validation on HIL/SIL and road tests.
 Powertrain Systems team develops powertrain system solutions for electric, hybrid electric, and fuel cell vehicles. We work with automotive and commercial OEMs from concept phase in down-selecting the best powertrain and driveline architectures. We source highly qualified subsystems: power generation, energy storage, and auxiliaries to integrate with our electric generators, traction motors, controllers, and vehicle control units (VCU). We use modeling & simulation, calibration, and optimization tools, powertrain dynamometers, and years of experience to deliver turnkey powertrain solutions. We also provide onsite technical support in vehicle installation, commissioning, calibration, optimizations, and performance testing.<br></br>
 The Software and Control Team is actively working on:
            <div className="subsheet2-li">
                <div>
                    <li>PM & IM Single Motor Controllers</li>
                    <li>Integrated Starter and Generator</li>
                    <li>Motor and Generator Unit</li>
                    <li>Belt Starter and Generator</li>
                    <li>Pure Electric Passenger Cars</li>
                </div>
                <div>
                    <li>Dual Motor Controller</li>
                    <li>Configurable multi-phase, multi-motor drive for HEV Bus (DDMH)</li>
                    <li>Vehicle Control Unit (VCU)</li>
                    <li>Hybrid Electrical Vehicles</li>
                    <li>Pure Electrical Vehicles</li>
                </div>
            </div>

        </div>

</div>

) 
const Subsection2 = () =>(
    <div className="subsheet-content">
        <div className="subsheet-content-inner">
            <span className="span-title">Why BOT Is Unique</span><br></br>
            We are using a model-based software development process for code generation. For motor control we use a specialized technology – field oriented (Vector) control is applied to all products.


        </div>
        
    
    </div>
    
    ) 
    const Subsection3 = () =>(
        <div className="subsheet-content">
         <div className="subsheet-content-inner">
         <span className="span-title">Projects & Processes</span><br></br>
         

        </div>
        <img className="subsheet-img" src={img6}/>
        </div>
        
        ) 
        const Subsection4 = () =>(
            <div className="subsheet-content">
             <div className="subsheet-content-inner">
             <span className="span-title">3-5 Year Objectives</span><br></br>
             We are going to build a common software platform for future products. This common platform can be quickly used for new applications and to meet customer requirements in a most efficient process.


        </div>
            
            </div>
            
            ) 


export default Subsheet2;