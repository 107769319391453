import React from "react";
import {FaBars, FaTimes,} from 'react-icons/fa';

import EVcatalog from "./EVcatalog";

class EVsheet extends React.Component{
    
    state = {
        showsection1: true,
        showsection2: false,
        showsection3: false,
        showsection4: false,
        showsection5: false,
        showsection6: false,
        showsection7: false,
        showsection8: false,
        showsection9: false,
        visible: false,
        
        
    }
  
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

     render(){

        const {showsection1,showsection2,showsection3,showsection4,showsection5,showsection6,showsection7,showsection8,showsection9,visible ,select} = this.state;
        
         return(
                <div className="hvacsheetwrapper">
                    
                   <div className='slide_nav'>     
                {/* <p className="slide_nav_button" onClick={() => this.setState({visible: !visible })}><FaBars size='30px'/></p> */}
            </div>
            <div className="hvacworksheet-top" >
                       
                       <div className="hvac-section-menu">
                           <div className="hsm-buttom"  onClick={() =>[ this.setState({
                               showsection1: true,
                               showsection2: false,
                               showsection3: false,
                               showsection4: false,
                               showsection5: false,
                               showsection6: false,
                               showsection7: false,
                               showsection8: false,
                               showsection9: false,
                               
                           
                           }),
                           this.scrollToTop()]
                           }   >
                           
                           <div className="hsm-title" style={{ color : (showsection1 ? '#4C75DF' : 'black') }}>EV Drive Motor</div>
                           
                           </div>

                       </div>

                       </div>
           
                    <div className="hvacworksheet-top-mobile" style={{ display: (visible ? 'flex' : 'none') }}>
                       
                        
                      
                        </div>

                        <div className="hvacsectionwrapper">
                        {showsection1 && (<Section1/>)}
                        

                        </div>

                </div>
         );
     }

}

const Section1 = () =>(
<div className="hvac-section-inner">
        
        <EVcatalog/>

</div>

) 



export default EVsheet;