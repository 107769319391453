import React, { useEffect } from "react";
import "./Survey.css";
//import Layout from "./Layout";
import Paper from "./Paper/index";
import Total from "./Total/index";
import PaperResultDetail from "./PaperResultDetail/index";
import { UserAuth } from "../Login/AuthContext";
const Surveyresults = () => {
  const { user, logout } = UserAuth();
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Do something before the page is unloaded, such as displaying a confirmation dialog
      event.preventDefault();
      event.returnValue = '';
    };

    const handleUnload = () => {
      // Do something before the page is unloaded, such as sending data to the server
      logout();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      // Clean up the event listeners when the component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  },[]);

  return (
    <div className="surveyresultswrapper">
       <Total/> 
       {/* <PaperResultDetail/> */}
      
    </div>
  );
};



export default Surveyresults;