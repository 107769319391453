import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyBN5BHiFOttkVgl3F_Ue-NWBwUzmXJmCC8",
  authDomain: "bomnaweb-88ab3.firebaseapp.com",
  projectId: "bomnaweb-88ab3",
  storageBucket: "bomnaweb-88ab3.appspot.com",
  messagingSenderId: "729878209672",
  appId: "1:729878209672:web:e9baa6c25d4b634a646b3d",
  measurementId: "G-4X2CGDH7DQ"
};

const firebaseApp = initializeApp(firebaseConfig);

export const firestore = getFirestore(firebaseApp);