import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import img1 from "./ourculture/img1.webp";

import img3 from "./ourculture/img3.webp";
import img4 from "./ourculture/img4.webp";
import img5 from "./ourculture/img5.webp";
import img6 from "./ourculture/img6.webp";
import img7 from "./ourculture/img7.jpg";
import img8 from "./ourculture/img8.webp";
import img9 from "./ourculture/img9.jpg";
import img10 from "./ourculture/img10.jpg";
import img11 from "./ourculture/img11.jpg";
import img12 from "./ourculture/img12.webp";
import img13 from "./ourculture/img13.webp";
import img14 from "./ourculture/img14.jpg";

const AutoplaySlider = withAutoplay(AwesomeSlider);
import './About.css'
function gallery(){

    return(
        <div className='gallerywrapper'>
            <div className='gallery-top'>
            <AutoplaySlider
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={10000}
        bullets={false}
        mobileTouch={true}
        
      >
        <div className='gallery-img' data-src={img1} />
    
        <div className='gallery-img' data-src={img3} />
        <div className='gallery-img' data-src={img4} />
        <div className='gallery-img' data-src={img5} />
        <div className='gallery-img' data-src={img6} />
        <div className='gallery-img' data-src={img7} />
        <div className='gallery-img' data-src={img8} />
        <div className='gallery-img' data-src={img9} />
        <div className='gallery-img' data-src={img10} />
        <div className='gallery-img' data-src={img11} />
        <div className='gallery-img' data-src={img12} />
        <div className='gallery-img' data-src={img13} />
        <div className='gallery-img' data-src={img14} />
       
        
      </AutoplaySlider>
            </div>
            

      <div className='gallery-block'>
        <div className="abs-quote-title">What People Have to Say About Us</div>
    <div className="abs-quote">
<div>“A focused team of professionals with giant accomplishments.”</div>
<div>“Vision, courage, cohesiveness.”</div>
<div>“Talented, innovative and ambitious.”</div>
<div>“Aggressive and rigorous”</div>
<div>“Capable, focused and fast-to-market”</div>
<div>“Ambitious”</div>
<div>“Growing, skunkworks”</div>
<div>“Amazing and smart co-workers, uncommon common sense!”</div>
    </div></div>
        </div>
        
    );
}
 

export default gallery;