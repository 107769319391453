import React, { useEffect,useRef, useState}from "react";
import { useForm } from 'react-hook-form';
import './Contact.css';
import mi from './Contactimage/mi1.webp'
import hou from './Contactimage/hou 1.webp'
import ind from './Contactimage/indiana 1.webp'
import mx from './Contactimage/mx 1.webp'
import ill from './Contactimage/chi1.webp'
import emailjs from 'emailjs-com';
import mars from './Contactimage/mars.gif'
import { UserAuth } from "../Login/AuthContext";
function Contact(){
 const [test, setTest] = useState("HVAC");
  const form = useRef();
  const { user, logout } = UserAuth();
  const handleClick = () => {
    window.location.assign('https://www.marsdelivers.com');
  };
  const sendEmail = (e) => {
     
      e.preventDefault();
    if(e.value === 'HVAC'){
      
    } else{

    }
    //emailjs.sendForm('service_nxnrdzh', 'template_kkmv5wa', form.current, 'ZN81IQBaykDmyWr-E')
    emailjs.sendForm('service_nxnrdzh', 'template_6pyw1fw', form.current, 'ZN81IQBaykDmyWr-E')
    .then(
      function(response) {
        //console.log("SUCCESS. status=%d, text=%s", response.status, response.text);
        alert("Message sent successfully!");
    },
      
      (error) => {
        console.log(error.text);
    });
     
        form.current.reset();
    
    
  };
  
  useEffect(() => {
       logout();
  }, [])

return (
  <div className="contactwrapper">
    <div className="ct-p">
    <ul className="contact-ul">
    
    <li className="contact-li">Broad-Ocean Motor does not sell individual parts to the public. For <span className="cp2">replacement </span>parts please contact the appliance warranty provider or <span className="cp1" onClick={handleClick} >www.marsdelivers.com</span>. <img className="mars" onClick={handleClick} src={mars}/></li>
    <li>If you have a question about our motor used in a specific <span className="cp2">Original Equipment Manufacturer(OEM)</span>’s HVAC / Appliance product, please contact that <span className="cp2">OEM</span> for answers. Broad-Ocean Motor company may not be able to answer those questions on their behalf.</li> 
    <li>For seeking new business relationship with Broad-Ocean Motor, please contact <span className="cp2">marketing@us.broad-ocean.com</span> or  <span className="cp2">sales@us.broad-ocean.com</span></li> 
    </ul>
   
    
    
    
    </div>
  
  <div className="contact-top">

  <form className="contact-form" ref={form} onSubmit={sendEmail} onClick={() => {
            setTest("HVAC");
          }}>
            
    <div className="form-left">
    {/* <div className="cp-child" >
      <p className="checkbox-p"><input className="radiobox" 
                                        name="prefertype"
                                        type="radio" 
                                        value='HVAC'
                                        //checked={test==="HVAC"}
                                        
      />HVAC</p>
      <p className="checkbox-p"><input  className="radiobox" 
                                        name="prefertype"
                                        type="radio" 
                                        value='EV'
                                        //checked={test ==="EV"}
                                        
       />EV</p>
      </div> */}
    
      <p className="form-p">First Name
      <input className="frame" required={true} name='first name' type="text" />
      </p>
      <p className="form-p">Last Name
      <input className="frame" required={true} name="last name" type="text"  />
      </p>
      <div className="contactprefer">Contact Preference
      <div className="cp-child">
      <p className="checkbox-p"><input className="checkbox" 
                                        name="prefertype"
                                        type="checkbox" 
                                        value='Email'
                                        
                                        
      />Email</p>
      <p className="checkbox-p"><input  className="checkbox" 
                                        name="prefertype"
                                        type="checkbox" 
                                        value='phone'
                                       
                                        
       />Phone</p>
      </div>     
      </div>
      <p className="form-p">Email
      <input className="frame" name="email" type="email"  />
      </p>
      
      <p className="form-p">Phone
      <input  className="frame" name="phone" type="tel"   />
      </p>
      <div className="form-location">
      <p className="form-p">Zip Code
      <input  className="frame" name="zip" type="tel"  />
      </p>
      <div className="loc"></div>
      <p className="form-p">Region
      <select  className="frame" name="region" >
        <option value="1">Select Region</option>
        <option value="Houston, Texas"> Houston, Texas</option>
        <option value="Whitestown, Indiana"> Whitestown, Indiana</option>
        <option value="Monterrey, Mexico"> Monterrey, Mexico</option>
        <option value="Novi, Michigan">  Novi, Michigan</option>
        <option value="Westmont, Illinois">  Westmont, Illinois</option>
      </select></p>
      </div>
      
      
      </div>
      <div className="form-right">
        <div className="form-p">Subject
      <input  className="frame" name="subject" type="text" required={true} />
      </div>
      <div className="form-p">Question/Comment
      <textarea className="frame1" name="message" placeholder="1000 Character Max" rows='10' cols='20' required={true} />
      
      </div>
      <input  className="form-botton" type="submit" value='Send Message' />
      
      </div>
      
    </form>
    
  </div>
  <div className="contact-bottom">

      <div className="cb-part">
          <div className="part-img"><img className="building" src={mi}/></div>
          <div className="part-text">
            <div className="cb-title">Broad-Ocean Technologies Michigan</div>
            <div className="cb-b">
            <p className="cb-p">30120 Hudson Drive <br></br>Novi, MI 48377</p>
            <div>
            <p className="cb-p">Telephone: +1-248-313-3790 <br></br>Email: info@us.broad-ocean.com</p>
            </div>
            </div>
            <p className="cb-p"><a href='https://www.google.com/maps/place/30120+Hudson+Dr,+Novi,+MI+48377/@42.5172144,-83.5123744,17z/data=!3m1!4b1!4m5!3m4!1s0x8824a8ac50b5c301:0xc815405a91e1c126!8m2!3d42.5172105!4d-83.5101857'>Directions</a></p>
          </div>
      
      </div>
      <div className="cb-part">
          <div className="part-img"><img className="building" src={hou}/></div>
          <div className="part-text">
          <div className="cb-title">Broad-Ocean Motor Houston</div>
          <div className="cb-b">
            <p className="cb-p">18140 Kickapoo Road <br></br>Waller, TX 77484</p>
            <div>
            <p className="cb-p">Telephone: +1-713-353-0100<br></br>Email: info@us.broad-ocean.com</p>
            </div>
            </div>
            <p className="cb-p"><a href='https://www.google.com/maps/place/Broad-Ocean+Motor+%2F+Manufacturing+Texas/@30.0459129,-95.8762589,15.21z/data=!4m5!3m4!1s0x8646d2c582ad7aa5:0x552da61e20788e80!8m2!3d30.0404926!4d-95.8697181'>Directions</a></p>
          </div>
      
      </div>
      <div className="cb-part">
          <div className="part-img"><img className="building" src={ind}/></div>
          <div className="part-text">
          <div className="cb-title">Broad-Ocean Motor Indiana</div>
          <div className="cb-b">
            <p className="cb-p">4900 Albert S. White Dr.<br></br>Whitestown, IN 46075</p>
            <div>
            <p className="cb-p">Telephone: +1 317-769-8700<br></br>Email: info@us.broad-ocean.com</p>

            </div>
            </div>
            <p className="cb-p"><a href='https://www.google.com/maps/place/4900+Albert+S.+White+Dr,+Whitestown,+IN+46075/@39.9814167,-86.377008,444m/data=!3m1!1e3!4m13!1m7!3m6!1s0x8813524928a8e3d9:0x14fd85c27291774!2s4900+Albert+S.+White+Dr,+Whitestown,+IN+46075!3b1!8m2!3d39.981804!4d-86.3776089!3m4!1s0x8813524928a8e3d9:0x14fd85c27291774!8m2!3d39.981804!4d-86.3776089'>Directions</a></p>
          </div>
      
      </div>
      <div className="cb-part">
          <div className="part-img"><img className="building" src={mx}/></div>
          <div className="part-text">
          <div className="cb-title">Broad-Ocean Motor Mexico</div>
          <div className="cb-b">
            <p className="cb-p">Av. Hilsa 560 <br></br>Guadalupe, N.L. 67132</p>
            <div>
            <p className="cb-p">Telephone: +52 81-8852-0600 <br></br>Email: info@us.broad-ocean.com</p>
            </div>
            </div>
            <p className="cb-p"><a href='https://www.google.com/maps/place/Av.+Hilsa+560,+Praderas+de+La+Enramada,+67329+Guadalupe,+N.L.,+Mexico/@25.3876172,-99.6989109,447828m/data=!3m1!1e3!4m5!3m4!1s0x8662e9d92fffe66b:0xa3be9473d62d2c83!8m2!3d25.6910383!4d-100.1417921'>Directions</a></p>
          </div>
      
      </div>
      <div className="cb-part">
          <div className="part-img"><img className="building" src={ill}/></div>
          <div className="part-text">
          <div className="cb-title">Broad-Ocean Motor Illinois</div>
          <div className="cb-b">
            <p className="cb-p">7955 S. Cass Ave <br></br>Suite 112 Darien, IL 60561</p>
            <div>
            <p className="cb-p">Telephone: +1 331-777-4337<br></br>Email: info@us.broad-ocean.com</p>
            </div>
            </div>
            <p className="cb-p"><a href='https://www.google.com/maps/place/7955+S+Cass+Ave+%23112,+Darien,+IL+60561/@41.7381508,-87.9847402,14z/data=!4m5!3m4!1s0x880e4f7ffd4aa52d:0xe33b1a1c385e6392!8m2!3d41.7429693!4d-87.972352'>Directions</a></p>
          </div>
      
      </div>

  </div>




  </div>
  





);




}
export default Contact;