import React from 'react';
import { useState, useEffect } from 'react';
import { Upload, Progress, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { storage } from '../../firebase-config';
import { UserAuth } from '../../Login/AuthContext';
import { ref, uploadBytesResumable } from 'firebase/storage';
import DatabaseController from './DatabaseController';
import xIcon from '../Images/xIcon.jpg';
import FileLinks from './FileLinks';
import Card from '../UI/Card';
import styles from './FileUpload.module.css';
import linkStyles from './FileLinks.module.css';

export default function FileUpload(props) {
    const [uploadQueue, setUploadQueue] = useState([]);
    const [uploadUpdate, setUploadUpdate] = useState(null);
    const [,setDataRetrieval] = props.DataRetrieval;

    const fileBatchDB = new DatabaseController();

    const uid = UserAuth().user.uid;

    // Initiates file data retrieval upon page load
    useEffect(() => setDataRetrieval(false), [setDataRetrieval]);
    useEffect(() => {
       if (!uploadQueue.length) {
        setUploadUpdate(null);
       }
    }, [uploadQueue]);

    // Simulates a post request to turn the Upload
    // component into just a file selector
    // (we handle the actual upload ourselves)
    const handleUploadRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);

        if (!uploadQueue.filter(currFile => currFile[0].name === file.name).length) {
            // Append to the uploadQueue state array
            setUploadQueue(prevState => {
                return [...prevState, [file, file.name, null]];
            });
        }
        else {
            return message.warning(`${file.name} is already in the upload queue!`)
        }
      };

    // Handle a file being removed
    // by filtering from the previous uploadQueue state array
    const handleRemoval = async file => {
        delete window[file[1] + 'UploadProgress']; // Clear upload progress global var
        delete window[file[1] + 'Paused']; // Clear upload status global var

        if (file[2]) {
            const cancelResult = file[2].cancel();

            if ((cancelResult) || (file[2]._state === 'paused')) {
                if (file[2]._transferred < file[2]._blob.size_) {
                    await fileBatchDB.DeleteFiles([file[1]]);
                    message.warning(`Cancelled upload of ${file[0].name}`);
                }
                setDataRetrieval(false);
            }
        }
        setUploadQueue(prevState => {
            return prevState.filter(x => x[0].uid !== file[0].uid);
        })
    }

    // Pause or resume an upload
    const toggleUpload = file => {
        if (window[file[1] + 'Paused']) {
            file[2].pause();
        }
        else {
            file[2].resume();
        }
        setUploadUpdate(true);
    }

    const getQueuedFileReference = file => {
        const newUploadQueue = [...uploadQueue];
        const targetFile = newUploadQueue.find(
            fileRef => fileRef[0].name === file[0].name
        );
        return [newUploadQueue, targetFile];
    }

    const setNickname = (file, nickname) => {
        const [newUploadQueue, nicknameFile] = getQueuedFileReference(file);

        nicknameFile[1] = nickname;
        setUploadQueue(newUploadQueue);
    }

    const setUploadTask = (file, task) => {
        const [newUploadQueue, taskFile] = getQueuedFileReference(file);

        taskFile[2] = task;
        setUploadQueue(newUploadQueue);
    }

    const generateNicknames = async () => {
        for (const file of uploadQueue) {
            let nickname = file[0].name;

            const isDuplicate = await fileBatchDB.InBatch(
                fileBatchDB.CreateKeyName(file[0].name), null);

            if (isDuplicate) {
                let dupeCounter = 1;
                nickname = `(${dupeCounter}) ${file[0].name}`;

                let notOriginal = await fileBatchDB.InBatch(
                    fileBatchDB.CreateKeyName(nickname), null);

                while (notOriginal) {
                    dupeCounter += 1;
                    nickname = `(${dupeCounter}) ${file[0].name}`;

                    notOriginal = await fileBatchDB.InBatch(
                        fileBatchDB.CreateKeyName(nickname), null);
                }
                setNickname(file, nickname);
            }
        }
    }

    // Handle the file upload
    const handleUpload = async () => {
        if (!uploadQueue.length) {
            message.warning("Please select at least one file to upload!")
            return;
        }

        await generateNicknames();

        fileBatchDB.CreateBatch(uploadQueue.map(file => file[1]), uid);

        const uploadTaskPromises = [];

        // Going through each file in the queue
        for (const file of uploadQueue) {
            const fileRef = ref(storage, file[1]);
            const uploadTask = uploadBytesResumable(fileRef, file[0]);

            uploadTaskPromises.push(uploadTask);

            window[file[1] + 'Paused'] = false;

            setUploadTask(file, uploadTask);

            // Whenever upload state changes, update the file's progress bar
            uploadTask.on('state_changed', uploadSnapshot => {
                // Get task progress, including the number of bytes uploaded 
                // and the total number of bytes to be uploaded
                const progress = Math.trunc((uploadSnapshot.bytesTransferred / uploadSnapshot.totalBytes) * 100);
                window[file[1] + 'UploadProgress'] = progress;
                setUploadUpdate(progress);
            });

            // Once a file has been uploaded
            uploadTask.then(async () => {
                await handleRemoval(file); // Remove uploaded file from the queue
                message.success(`${file[0].name} was uploaded successfully!`)
            }, (error) => {
                if (!error.message) {
                    message.error(`${file[0].name} failed to upload!`);
                }
            })
        }
        await Promise.all(uploadTaskPromises).catch(() => {});
        setDataRetrieval(false); // Reload files from storage
    }

    const handleClear = async () => {
        if (!uploadQueue.length) {
            return message.warning('Queue is empty!');
        }

        setUploadQueue([]);
    }

    return (
        <div className={styles['uploader-wrapper']}>
            <div className={styles['uploader-spacer']}>
                <h2>Upload file(s) for employee viewing</h2>
                <Upload customRequest={handleUploadRequest} multiple={true}
                showUploadList={false} fileList={[]}>
                    <Button icon={<UploadOutlined />}>Click or drag/drop to select file(s) to upload</Button>
                </Upload>
                <ol className={styles.ol}>{uploadQueue.map((file, index) => <Card key={index}>
                        <p className={styles['uploader-queue-txt']}>{file[0].name}</p>
                        {window[file[1] + 'UploadProgress'] > 0 && 
                            <Progress className={styles['ant-progress-bg']} 
                                percent={window[file[1] + 'UploadProgress']} />}
                        <button className={linkStyles.Button}
                            onClick={async () => await handleRemoval(file)} 
                            title="Remove file from queue">
                                <img src={xIcon} alt='xIcon' className={styles.xIcon} />
                        </button>
                        {file[2] && <button
                            className={linkStyles.Button}
                            onClick={() => {
                                window[file[1] + 'Paused'] = !window[file[1] + 'Paused'];
                                toggleUpload(file);
                            }}
                            title={window[file[1] + 'Paused'] ? 'Resume Upload' : 'Pause Upload'}>
                                {window[file[1] + 'Paused'] ?
                                 <PlayArrowIcon className={styles['pause-resume']}/> : 
                                 <PauseIcon className={styles['pause-resume']}/>}
                        </button>}
                    </Card>)}</ol>
                {uploadUpdate === null && <button className={styles['uploader-button']} onClick={handleUpload}>Upload</button>}
                {uploadUpdate === null && <button className={styles['clear-button']} onClick={handleClear}>Clear</button>}
            </div>
            <hr className={styles.divider} />
            <div>
                <FileLinks DataRetrieval={props.DataRetrieval} 
                HeaderTxt="Manage Uploaded Files Below"
                StandAlone={false} />
            </div>
        </div>
    );
}