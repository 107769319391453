import React from "react";

import './Newspage.css';
import img from '../newsimg/newsimg3.webp'
function Newsthree(){


return (
 
  <div className="newspagewrapper">
      <div className="newspage-inner">
        <div className="newspage-title">Dayang Electric plans to acquire Shanghai Electric Drive for 3.5 billion</div>
        <div className="newspage-date">July 23, 2015</div>
        <div className="newspage-img"><img className="np-img" src={img} /></div>
        <div className="newspage-text">Dayang( 5.290 , -0.02 , -0.38% ) (002249) released a fixed increase plan on Thursday evening. The company plans to purchase 100% equity of Shanghai Electric Drive by issuing shares and paying cash, and raise funds matching funds. </div>
        <div className="newspage-text">According to the plan, the company intends to invest in Shanghai Shenganneng Industrial Co., Ltd., Ningbo Yunsheng( 8.860 , -0.02 , -0.23% ) Co., Ltd., Ningbo Yunsheng Investment Co., Ltd., Shanghai Ainaida Industrial Co., Ltd., and Ningbo Jianzhi Management Partnership (Limited Partnership), Beijing Zhongke Yineng New Technology Co., Ltd., Shenzhen Zhicheng Dongyuan Investment Partnership (Limited Partnership), Tibet Tianying Investment Co., Ltd., Ningbo Linshiyuan Investment Center (Limited Partnership), Ningbo Zhuoyu Investment Management Partnership (Limited Partnership) issued 426 million shares at an issue price of 6.34 yuan per share, and paid 799 million yuan in cash to purchase 100% of Shanghai Electric Drive.</div>
        <div className="newspage-text">Taking March 31, 2015 as the evaluation base date, the valuation of Shanghai Electric Drive was 3.51 billion yuan, compared with its audited book value of 302 million yuan of shareholders' equity attributable to the parent company, an appreciation of 3.208 billion yuan, and an appreciation rate of 1062.9% . After negotiation with the counterparty, the transaction price of the underlying assets was finally determined at 3.5 billion yuan.</div>
        <div className="newspage-text">According to the data, Shanghai Electric Drive is one of the leading companies in China's new energy ( 4.750 , 0.01 , 0.21% )Since its establishment in July 2008, it has been engaged in the research and development, production, sales and technical development services of new energy vehicle drive motor systems. Provide complete permanent magnet synchronous drive motor system solutions. Shanghai Electric Drive achieved operating income of 205 million yuan, 602 million yuan and 98.5182 million yuan from January to March in 2013, 2014 and 2015.</div>
        <div className="newspage-text">In addition, Dayang Electric intends to issue no more than 200 million shares to no more than 10 other specific investors at a price of no less than 7.17 yuan per share, and raise matching funds of no more than 2.5 billion yuan, which will be used to pay for this transaction after deducting the issuance costs. Cash consideration, new energy vehicle motor system industrialization capacity building project, AMT-based commercial vehicle plug-in hybrid power system industrialization project, motor drive system research and development and pilot base construction project, taxes and intermediary fees for this merger and acquisition transaction, supplementary Liquidity.</div>
        <div className="newspage-text">The counterparty of the transaction promised that the net profit of Shanghai Electric Drive in 2015, 2016 and 2017 was not less than 94 million yuan, 138 million yuan and 189 million yuan respectively.</div>
        <div className="newspage-text">Dayang Electric said that this transaction will optimize the company's business structure, expand the company's business scale, and strengthen the company's main business, which will help to further enhance the listed company's comprehensive competitiveness, market expansion capabilities, and resource integration capabilities, thereby enhancing the company's profitability. Enhance the ability to resist risks and sustainable development to achieve rapid development of the company. (Panorama Network)</div>
      </div>




  </div>





);




}
export default Newsthree;