import React,{useEffect, useState} from "react";
import './Layout.css';
import {FaBars, FaTimes,} from 'react-icons/fa';
import { BsGlobe } from "react-icons/bs";
import {NavLink, Link} from 'react-router-dom';
// import bologo from './Layoutimage/BOLOGO.webp'
import bologo from './Layoutimage/icon.PNG'


function Header(){
    const [click, setClick] = useState(false);
    const [mobileView, setMobileView] = useState(false)
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const scrolltop = () =>{
        window.scrollTo({top:0});
    };
    const showButton = () => {
        window.innerWidth <= 1454 ? setMobileView(true) : setMobileView(false);
        
    };
   const handleUnload = () => {
      // Do something before the page is unloaded, such as sending data to the server
      logout();
    };


    useEffect(() => {
        showButton();

        window.addEventListener('resize', showButton);
        return () => {
            window.removeEventListener('resize', showButton)
        }
    }, []);
    const navberlist = {
        0:{
            name:'Home',
            link:'/Home',
            exact: "true"
        },
        1:{
            name:'About Us',
            link:'/About',
            exact: false
        },
        2:{
            name:'Careers',
            link:'/Careers',
            exact: false
        },
        3:{
            name:'News',
            link:'/News',
            exact: false
        },
        4:{
            name:'Contact Us',
            link:'/Contact',
            exact: false
        },
    };
    


return(

    
    <div className="headerwrapper">
        
    
         
          <div className="header-left">
            <div className="logo">
                
                <Link to ='/Home'><img style={{ width: 100,height:78, }} className="headerlogo" src={bologo} alt='Bologo'/></Link>
            </div>
            
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    {/* {
                        Object.values(navberlist).map((info, index) => (
                            <li className="nav-item" key={index}>
                                <NavLink exact={info.exact}
                                         to={info.link}
                                         className='nav-link'
                                         activeClassname='nav-link-active'
                                         onClick={closeMobileMenu}
                                >
                                    {info.name}
                                </NavLink>



                            </li>


                        )
                        )

                    } */}

                    <li className="nav-item"><NavLink to='/Home' onClick={scrolltop}>Home</NavLink></li>
                    <li className="nav-item"><NavLink to='/About' onClick={scrolltop}>About Us</NavLink></li>
                    <li className="nav-item"><a href="https://broadocean.applicantpro.com/jobs/">Careers</a></li>
                    <li className="nav-item"><NavLink to='/News' onClick={scrolltop}>News</NavLink></li>
                    <li className="nav-item"><NavLink to='/Contact' onClick={scrolltop}>Contact</NavLink></li>
                   
                    
                    
                    </ul>
                    </div>
                <div className="header-right">
                <div className="header-btn"><NavLink className="btn-nav" to='/Location'>
                    <div className="dropdown" >
                        <BsGlobe size='30px' color="black"/>
                        </div><div className="btn-reg" >Regions</div>
                        </NavLink>
                        
                
                </div>
                         
                <div className="menu-icon" onClick={handleClick}>
                <i>{mobileView ? click ? <FaTimes size='30px'/> : <FaBars size='30px'/> : null}</i>
                            </div> 
                         
                         
                         
                         
                         </div>

                        
                         
                            
                    </div>
                    
                    
       
                       
            
    
            


    





);


}

export default Header;