import React from "react";

import './Catalog.css';

import img1 from '../../Classimg/airconditioner.webp'
import dm1401 from './Cata-img/hvacomdm140-1.webp'
import dm1402 from './Cata-img/hvacomdm140-2.svg'
import dm1403 from './Cata-img/hvacomdm140-3.webp'
function Catalog3(){
    let products = 
    [
      {
         id: 1,
         title: "DM 140",
         part:'Part#:C741V2',
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
         
      },
      {
         id: 2,
         title: "DM 140",
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
      },
      {
         id: 3,
         title: "DM 140",
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
      },
      {
         id: 4,
         title: "DM 140",
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
      },
      {
         id: 5,
         title: "DM 140",
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
      },
      {
         id: 6,
         title: "DM 140",
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
      },
      {
         id: 7,
         title: "DM 140",
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
      },
      {
         id: 8,
         title: "DM 140",
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
      },
      {
         id: 9,
         title: "DM 140",
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
      },
      {
         id: 10,
         title: "DM 140",
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
      },
      {
         id:11,
         title: "DM 140",
         Rotation: "Rotation viewing lead end: CW or CCW ",
         Technical: "Technical Feature: PWM/taps",
         protection: 'Type of protection: IP44',
         Insulation: 'Insulation: B', 
         Bearing: 'Bearing: Ball bearings',
         Approval:'Approval: UL, CCC',
         Amb:"Amb. Temp: -20 ~ +65℃",
         Application: ' Application: Pump',
         image1: dm1401,
         image2: dm1402,
         image3: dm1403,
      },
     
   ];
  
  
return (

  <div className="catalogwrapper">
    <div className="hvac-section-inner-top">
        <div className="hsit-right"> 
        <div className="ev-section-inner-title">A/C</div>
        <div className="ev-section-inner-title">Outdoor Motor</div>
        </div>
    

        <img className="hsit-left" src={img1}/>
        </div>
    <div className="cata-top">
     
    {products.map((pro,id) => {

        return(
            <div className="cata-inner" key={id}>
               <div className="cata-inner-top">   
               <div className="cata-inner-top-left">
               <div className="cata-title">{pro.title}</div>
               <div className="cata-text">{pro.part}</div>
               <div className="cata-img"><img className="cataimg" src={pro.image1}/></div>
                
                
               </div>
               <div className="cata-inner-top-right">
               <div className="cata-text">{pro.Rotation}</div>
               <div className="cata-text">{pro.Technical}</div>
                <div className="cata-text">{pro.protection}</div>
                <div className="cata-text">{pro.Insulation}</div>
                <div className="cata-text">{pro.Bearing}</div>
                <div className="cata-text">{pro.Approval}</div>
                <div className="cata-text">{pro.Amb}</div>
                <div className="cata-text">{pro.Application}</div>
                
                </div>
               </div>
                <div className="cata-inner-buttom">
                <div className="cata-img"><img className="cataimg2" src={pro.image2}/></div>
                <div className="cata-text">*All specifications and parameters are customizable </div>
                <div className="cata-img"><img className="cataimg3" src={pro.image3}/></div>
                </div>
            
                {/* <NavLink to={pro.to}><button className="cata-btn">View</button></NavLink> */}
        
            </div>
        );







    })}
    
    </div>



   
  </div>





);




}
export default Catalog3;