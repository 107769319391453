
import React,{useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import './Location.css';
import {RiArrowDropDownLine, RiArrowDropUpLine} from 'react-icons/ri';
import { UserAuth } from "../Login/AuthContext";
function Location(){
  const handleClick = () => setClick(!click);
  const handleClick2 = () => setClick2(!click2);
  const handleClick3 = () => setClick3(!click3);
  const [click, setClick] = useState(false);
  const [click2, setClick2] = useState(false);
  const [click3, setClick3] = useState(false);
  const handleClick4 = () => setClick4(!click4);
  const handleClick5 = () => setClick5(!click5);
  const handleClick6 = () => setClick6(!click6);
  const [click4, setClick4] = useState(false);
  const [click5, setClick5] = useState(false);
  const [click6, setClick6] = useState(false);
  const { user, logout } = UserAuth();
  useEffect(() => {
    logout();
}, [])
return (
  <div className="locationwrapper">
   
    <div className="l-title">Our Locations</div>
    
    <div className="l-menu-wrapper">
  
      <div className="na" onClick={handleClick} >
        North America
        <i className="tm-icon">{click ? <RiArrowDropUpLine size='30px' color="blue"/> : <RiArrowDropDownLine size='30px' color="blue"/>}</i>
      </div>


      <div className={click ? 'treemenu' : 'treemenu active'}>
        <div className="menu-general">
              <div className="country-name" onClick={handleClick2}  >
              United State
              </div>
              <div className={click2 ? 'usmenu' : 'usmenu active'}>
                <div className="us-list1">
                  <div className="ul-title">BROAD-OCEAN MOTOR</div>
                  <li className="us-li"><NavLink to='/Mic'>Broad-Ocean Technologies</NavLink></li>
                  <li className="us-li"><NavLink to='/Hou'>Broad-Ocean Motor-Houston</NavLink></li>
                  <li className="us-li"><NavLink to='/Ind'>Broad-Ocean Motor-Indiana</NavLink></li>
                </div>
                <div className="us-list1">
                  <div className="ul-title">PRESTOLITE</div>
                  <li className="us-li"><a href="https://www.prestolite.com/north-america/">Prestolite Electric NA</a></li>
                  
                </div>
              </div>
              </div>
              
              <div className="menu-general">
              <div className="country-name" onClick={handleClick3} >
              Mexico
 
              </div>
              <div className={click3 ? 'usmenu' : 'usmenu active'}>
              <div className="us-list1">
                  <div className="ul-title">BROAD-OCEAN MOTOR</div>
                  <li className="us-li"><NavLink to='/Mx'>Broad Ocean Motor de México</NavLink></li>
                  
                </div>
              </div>
              </div>
      </div>

      <div className="na" onClick={handleClick4} >
        Asia
        <i className="tm-icon">{click4 ? <RiArrowDropUpLine size='30px' color="blue"/> : <RiArrowDropDownLine size='30px' color="blue"/>}</i>
      </div>


      <div className={click4 ? 'treemenu' : 'treemenu active'}>
      <div className="menu-general">
              <div className="country-name" onClick={handleClick5} >
              China
              
              </div>
              <div className={click5 ? 'usmenu' : 'usmenu active'}>
              <div className="us-list1">
                  <div className="ul-title">BROAD-OCEAN MOTOR</div>
                  <li className="us-li"><a href="http://www.broad-ocean.com/broad-ocean-cn/index.html" >Zhongshan Broad-Ocean Motor</a></li>
                  <div className="ul-title">Shanghai</div>
                  <li className="us-li"><a href="https://www.chinaedrive.com/">Shanghai Edrive</a></li>
                  
                </div>
                <div className="us-list1">
                  <div className="ul-title">PRESTOLITE</div>
                  <li className="us-li"><a href="http://www.prestolite-bj.com/">Prestolite Electric CN(Beijing)</a></li>
                  <div className="ul-title">WUHU</div>
                  <li className="us-li"><a href="https://www.whgnr.com/">Wuhu GNR</a></li>
                  
                </div>
              </div>
              
             </div>
             {/* <div className="menu-general">
              <div className="country-name" onClick={handleClick6} >
              Vietnam
             
             </div>
             <div className={click6 ? 'usmenu' : 'usmenu active'}>
              <div className="us-list1">
                  <div className="ul-title">BROAD-OCEAN</div>
                  <li className="us-li">
                  
                  
                  
                  Ocean Motor de México</li>
                  
                </div>
              </div>
              </div> */}
             
      </div>
      



    </div>

    <div className="l-block"></div>
  </div>
  





);




}
export default Location;