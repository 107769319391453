import React,{Component} from "react";

import './Michigan.css'
import img5 from './micimg/micimg5.webp'

class Subsheet extends React.Component{
    state = {
        showsubsection1: true,
        showsubsection2: false,
        showsubsection3: false,
        showsubsection4: false,
       
    }
   
    
    

     render(){

        const {showsubsection1,showsubsection2,showsubsection3,showsubsection4} = this.state;
        const {color} = this.state;
         return(
               <div className="subsheetwrapper">
                   <div className="subsheet-section-title">Powertrain Systems</div>
                   <div className="subsheet-menu-inner">
                    
                   <div className="ssm-block"><div className="subsheet-section-menu">
                        
                        <div className="ss-buttom" onClick={() => this.setState({
                            showsubsection1: false,
                            showsubsection2: false,
                            showsubsection3: false,
                            showsubsection4: true,
                        
                        })} >
                        
                        <div className="ss-title">3-5 Year Objectives</div>
                        
                        </div>

                    </div>

                    <div className="subsheet-section-menu">
                        
                            <div className="ss-buttom" onClick={() => this.setState({
                                showsubsection1: false,
                                showsubsection2: false,
                                showsubsection3: true,
                                showsubsection4: false,
                            
                            })} >
                            
                            <div className="ss-title">Projects & Processes</div>
                            
                            </div>

                        </div>
                        </div>
                   




                        <div className="ssm-block"> 
                        <div className="subsheet-section-menu">
                            
                            <div className="ss-buttom" onClick={() => this.setState({
                                showsubsection1: false,
                                showsubsection2: true,
                                showsubsection3: false,
                                showsubsection4: false,
                            
                            })} >
                            
                            <div className="ss-title">Why BOT Is Unique</div>
                            
                            </div>

                        </div>

                   <div className="subsheet-section-menu">
                            
                            <div className="ss-buttom" onClick={() => this.setState({
                                showsubsection1: true,
                                showsubsection2: false,
                                showsubsection3: false,
                                showsubsection4: false,
                                
                            
                            })} >
                            
                            <div className="ss-title">What we do</div>
                            
                            </div>

                        </div>
                        </div>
                       

                        
                        
                      




                   </div>
                   <div className="subsheet-seaction">
                        {showsubsection1 && (<Subsection1/>)}
                        {showsubsection2 && (<Subsection2/>)}
                        {showsubsection3 && (<Subsection3/>)}
                        {showsubsection4 && (<Subsection4/>)}
                        </div>
               </div>
         );
     }

}

const Subsection1 = () =>(
<div className="subsheet-content">
<div className="subsheet-content-inner">
<span className="span-title">What We Do</span><br></br>
            Powertrain Systems team develops powertrain system solutions for electric, hybrid electric, and fuel cell vehicles. We work with automotive and commercial OEMs from concept phase in down-selecting the best powertrain and driveline architectures. We source highly qualified subsystems: power generation, energy storage, and auxiliaries to integrate with our electric generators, traction motors, controllers, and vehicle control units (VCU). We use modeling & simulation, calibration, and optimization tools, powertrain dynamometers, and years of experience to deliver turnkey powertrain solutions. We also provide onsite technical support in vehicle installation, commissioning, calibration, optimizations, and performance testing.

        </div>

</div>

) 
const Subsection2 = () =>(
    <div className="subsheet-content">
        <div className="subsheet-content-inner">
            <span className="span-title">Why BOT Is Unique</span><br></br>
            The team consists of highly experienced and talented individuals with significant depth in the xEV aspect of the automotive industry. They are focused on and passionate about reducing global emissions by making highly fuel efficient vehicles. We’re passionate about xEV technologies, we have state-of-the-art test equipment, and can use various vehicle platforms owned by our vehicle leasing division (a sister company) to implement our designs in a fast-paced way.

        </div>
        
    
    </div>
    
    ) 
    const Subsection3 = () =>(
        <div className="subsheet-content">
         <div className="subsheet-content-inner">
         <span className="span-title">Projects & Processes</span><br></br>
         DDMH Direct-Drive Multiple-Mode Hybrid) is a highly integrated and optimized plug-in hybrid electric powertrain system. It consists of electric power generation, electric traction drive, energy storage, and control systems. It has been developed for transit buses. The system is a modular and intelligently controlled hybrid electric drive system. It can be utilized 8.5 – 18 meter city buses as well as double-layer city buses and delivery trucks/vans. 

        </div>
        <img className="subsheet-img" src={img5}/>
        </div>
        
        ) 
        const Subsection4 = () =>(
            <div className="subsheet-content">
             <div className="subsheet-content-inner">
             <span className="span-title">3-5 Year Objectives</span><br></br>
             We aim to design and deliver xEV powertrain systems that are unsurpassed in performance, reliability, and cost. Our objectives are to become a leading system integrator and to put several system products to production in the next 3-5 years.


        </div>
            
            </div>
            
            ) 


export default Subsheet;