import React from "react";
import './EV.css'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import img1 from '../Classimg/evmotor.jpg'
import e1 from './EVimg/e1.webp'
import e2 from './EVimg/e2.webp'
import e3 from './EVimg/e3.webp'
import e4 from './EVimg/e4.webp'
import e5 from './EVimg/e5.webp'
import e6 from './EVimg/e6.webp'
import e7 from './EVimg/e7.webp'

function EVcatalog(){
    let products = 
    [
      {
         id: 1,
         title: "EP35",

         Rotation: "Assembly peak power: 35kW",
         Technical: "Assembly peak torque: 1250Nm",
         protection: 'Maximum motor speed: 11000rpm',
         Insulation: 'Assembly rated power: 18.5kW', 
         Bearing: 'Assembly dimension: 390mm * 445mm * 345mm',
         Approval:'Matching vehicle model: A00 EV',
         Amb:[ 'Product features : Best cost performance ratio, Export to European and South American markets, Top market volume for products with same power level',
            '', 
           '', 
            '',
        ],
         image1: e1,

         
      },
      {
         id: 2,
         title: "EP55",
         Rotation: "Assembly peak power: 55kW",
         Technical: "Assembly peak torque: 1400Nm",
         protection: 'Maximum motor speed: 10000rpm',
         Insulation: 'Assembly rated power: 25kW', 
         Bearing: 'Assembly dimension: 442mm * 414.5mm * 332.5mm',
         Approval:'Matching vehicle model: A0 EV',
         Amb:"Product features: High localization rate, good NVH, Applied for most power range of mini vehicles with high cost performance ratio",
         image1: e2,

      },
      {
         id: 3,
         title: "EP100",
         Rotation: "Assembly peak power: 100kW",
         Technical: "Assembly peak torque: 2400Nm",
         protection: 'Maximum motor speed: 14000rpm',
         Insulation: 'Assembly rated power: 40kW', 
         Bearing: 'Assembly dimension: 442.5mm * 435mm * 295mm',
         Approval:'Matching vehicle model: A/mini SUV EV',
         Amb:"Product features:Best cost performance ratio, NVH design",
         image1: e3,

      },
      {
         id: 4,
         title: "EP120",
         Rotation: "Assembly peak power: 120kW",
         Technical: "Assembly peak torque: 2700Nm",
         protection: 'Maximum motor speed: 12000rpm',
         Insulation: 'Assembly rated power: 60kW', 
         Bearing: 'Assembly dimension: 499mm * 483mm * 430mm',
         Approval:'Matching vehicle model: Class A/Mini SUV EV',
         Amb:"Product features: The first mass production three-in-one products in China, the largest inventory at domestic and foreign markets, excellent motor vibration and noise performance",
         image1: e4,

      },
      {
         id: 5,
         title: "EP160",
         Rotation: "Assembly peak power: 160kW",
         Technical: "Assembly peak torque: 3500Nm",
         protection: 'Maximum motor speed: 16000rpm',
         Insulation: 'Assembly rated power: 75kW', 
         Bearing: 'Assembly dimension: 460mm * 460mm * 307mm',
         Approval:'Matching vehicle model: A+/B/big SUV EV',
         Amb:"Product features: TC377 multi-core control, functional safety ASIL-D, AUTOSAR software architecture",
         image1: e5,

      },
      {
         id: 6,
         title: "EH150 Traction Inverters",
         Rotation: "Driving peak current: 500A",
         Technical: "Peak current of power generation: 400A",
         protection: 'Boost power: 60kW',
         image1: e6,

      },
      {
         id: 7,
         title: "48V BSG",
         Rotation: "Assembly peak power: 10kW",
         Technical: "Peak motor torque: 40Nm",
         image1: e7,

      },

     
   ];
   
  
return (

  <div className="evcatalogwrapper">
<div className="ev-section-inner-top">
                    <div className="evit-right"> 
                    <div className="hvac-section-inner-title">EV Motors</div>
                    
                    </div>
                        <img className="evit-left" src={img1}/>
                    </div>
    <div className="cata-top">
     
    {products.map((pro,id) => {
     
        return(
            <div className="evcata-inner" key={id}>
                 
               <div className="evcata-inner-top-left">
               <div className="evcata-title">{pro.title}</div>
               <div className="cata-text">{pro.part}</div>
   
               <div className="evcata-img"><TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
         <div>
            <div className="tools">
              <button className="catalog-zoom-button" onClick={() => zoomIn()}>Zoom In</button>
              <button className="catalog-zoom-button" onClick={() => zoomOut()}>Zoom Out</button>
              <button className="catalog-zoom-button" onClick={() => resetTransform()}>Reset</button>
            </div>
            <TransformComponent>
              <img className="evcataimg" src={pro.image1}/>
             
            </TransformComponent>
          </div>
        )}
      </TransformWrapper>
      </div>
               {/* <img className="evcataimg" src={pro.image1}/> */}
                
               </div>
               <div className="evcata-inner-top-right">
               <div className="evcata-text">{pro.Rotation}</div>
               <div className="evcata-text">{pro.Insulation}</div>
               <div className="evcata-text">{pro.Technical}</div>
                <div className="evcata-text">{pro.protection}</div>
                
                <div className="evcata-text">{pro.Bearing}</div>
                {/* <div className="evcata-text">{pro.Approval}</div> */}
                <div className="evcata-text">{pro.Amb}</div>
                <div className="evcata-text">{pro.Application}</div>
                
                </div>
               
              
            
                {/* <NavLink to={pro.to}><button className="cata-btn">View</button></NavLink> */}
        
            </div>
        );







    })}
    
    </div>



   
  </div>





);




}
export default EVcatalog;