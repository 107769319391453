import React, { useEffect,useRef, useState}from "react";
import { UserAuth } from "../Login/AuthContext";
import './Michigan.css';
import img1 from './micimg/micimg1.webp'
import Worksheet from './Worksheet'
function Michgan(){
  const { user, logout } = UserAuth();
  useEffect(() => {
    logout();
}, [])

return (

  <div className="michganwrapper">

        <div className="mic-top">
            <img className="mic-img" src={img1}/>
            <div className="mic-top-title">Broad-Ocean Technologies</div>
            <div className="mic-top-text">Broad-Ocean Technologies, LLC designs and develops advanced electric motors, motor controllers, powertrain systems, vehicle or hybrid supervisory controllers that are used in a variety of automotive and industrial applications. From components to systems, we deliver xEV powertrain solutions and products to the growing electric vehicle market. Supported by our parent company Broad-Ocean Motor, Broad-Ocean Technologies, LLC is an autonomous, full-service resource serving the North American region with local, on-site design, development, validation and test capabilities.</div>
            <div className="mic-top-text">Located in Novi, MI, we have  over 50,000 square feet of office, lab and test facilities.  We are the R & D division of Broad-Ocean Motor EVBU.  We are an advanced technical center focused on the development and testing of  leading edge technology for Hybrid and Electric vehicle powertrain systems and e-Drive systems.</div>
        </div>
  <div className="mic-mid-title">What We Do</div>
  <Worksheet/>
  <div className="mic-block"></div>
  </div>





);




}
export default Michgan;