import React, { useEffect,useRef, useState} from "react";

import './Mexico.css';
import img1 from './mximg/mximg1.webp'
import img2 from './mximg/mximg2.webp'
import img3 from './mximg/mximg3.webp'
import img4 from './mximg/mximg4.webp'
import img5 from './mximg/mximg5.webp'
import img6 from './mximg/mximg6.webp'
import img7 from './mximg/mximg7.webp'
import img8 from './mximg/mximg8.webp'
import img9 from './mximg/mximg9.webp'
import { UserAuth } from "../Login/AuthContext";
function Mexico(){
    const { user, logout } = UserAuth();
    useEffect(() => {
        logout();
   }, [])
return (
    <div className="mxwrapper">
            <div className="mx-top">
             <div className="mx-top-img">
                 <img className="mx-i" src={img1}/>
            </div>
                 <div className="mx-top-content">

            <div className="mx-top-title">Broad-Ocean Motor de Mexico</div>

                <div className="mx-top-text">Broad-Ocean Motor de México, fundada en Abril del 2014, es una compañía de origen Chino de alta tecnología que pertenece 
                al grupo Broad-Ocean Motor, con sede en Zhongshan de la provincia de Cantón; Broad-Ocean Motor de México está comprometida con sus clientes globales a 
                proveer soluciones para sistemas de impulsión por motor.</div>

                <div className="mx-top-text">Broad-Ocean Motor de México, como proveedor global de motores eléctricos, sirve a docenas de clientes en el Norte del 
                Continente y cuenta con una capacidad de producción anual aproximada de 4 millones de motores para fines electrodomésticos. Broad-Ocean Motor de México 
                posee las certificaciones ISO9001, ISO14001 y está dentro de los 10 principales proveedores de Carrier UTC. Además, todos sus productos cuentan con 
                certificación CCC, UL, CSA, VDE, TUV, CE, entre otras.</div>

                <div className="mx-mid-title">SEGURIDAD Y MEDIO AMBIENTE</div>

                <div className="mx-top-text">En Broad-Ocean Motor de México estamos comprometidos a ofrecer un ambiente de trabajo seguro y 
                saludable a través de procedimientos, capacitaciones y medidas de seguridad, entre otras actividades. Creemos 100% que todo 
                accidente puede ser prevenido, por lo que desarrollamos una cultura de seguridad en el trabajo en el que la Seguridad y Salud 
                no es solo prioridad, sino también un valor que día a día se vive, asegurándonos que todo empleado regrese a su casa de la 
                misma forma que llego al trabajo.</div>

                <div className="mx-top-text">Actualmente, y gracias a todos los empleados de Broad-Ocean Motor de México, la empresa ha 
                alcanzado un gran logro de +1000 días sin accidentes, lo cual nos hace muy orgullosos de nuestra forma de trabajar.</div>

                <div className="mx-top-text">En Broad-Ocean Motor de México estamos comprometidos en ser una empresa dispuesta a cumplir con las 
                responsabilidades sociales. Para lograr una excepcional seguridad e higiene, a través de la mejora continua, es una parte importante 
                de nuestro trabajo.</div>

                <div className="mx-top-text">Así haya sido en el pasado, en la actualidad o en el futuro,  todos nuestros productos, servicios y soluciones  
                son creados pensando en la protección del medio ambiente y la salud humana pues esto es algo que consideramos fundamental en nuestra cultura 
                corporativa: la creación de una producción segura, saludable y cómoda, así como un buen ambiente de trabajo para nuestros empleados, asegurando su 
                salud y seguridad en nuestras instalaciones.</div>

                <div className="mx-top-text">Un elemento esencial para la sustentabilidad en Broad-Ocean Motor de México es el uso consciente, racional y eficiente 
                de los recursos naturales, así como la generación de una cultura ambiental en la empresa y con nuestras partes interesadas.</div>

                <div className="mx-top-text">Broad-Ocean Motor de México es una empresa limpia que no generan emisiones a la atmósfera ni utilizan agua en sus 
                procesos. Como micro-generador de residuos, tanto peligrosos y de manejo especial; se encarga de darle una disposición correcta y asegurando que la 
                mayor parte de los residuos sean reciclados.</div>

                <div className="mx-top-text">Broad-Ocean Motor de México cuenta la certificación vigente de ISO 14001:2015 desde el 2017, cuyo Sistema de Gestión 
                Ambiental aplica a todos procesos, productos y servicios relacionados con el ensamble mecánico, inspección y prueba eléctrica, almacenamiento y 
                distribución de motores de corriente alterna y directa para aplicaciones de uso residencial.</div>

                <div className="mx-top-text">En Broad-Ocean Motor de México estamos comprometidos en: </div>

                <div className="mx-top-text">Desarrollar nuestras operaciones de ensamble, prueba y almacenamiento de motores con el menor impacto 
                ambiental posible.</div>

                <div className="mx-top-text">Prevenir la contaminación mediante operaciones limpias haciendo uso racional de la energía eléctrica y los consumibles, 
                así como gestionar correctamente los residuos generados por nuestras actividades.</div>

                <div className="mx-top-text">Cumplir con el marco regulatorio y otros requisitos en materia ambiental que competen a la Organización.</div>

                <div className="mx-top-text">Mejorar continuamente nuestro Sistema de Gestión Ambiental para lograr una mejora en el desempeño ambiental.</div>

            <div className="mx-mid-title">FILOSOFÍA CORPORATIVA</div>

                <div className="mx-top-text">En Broad-Ocean Motor de México tenemos el compromiso de convertirnos en el 
                proveedor de soluciones motor más competitivo del mundo.</div>

                <div className="mx-top-text">En Broad-Ocean Motor de México estamos comprometidos en ofrecer a nuestros clientes productos seguros eficientes y 
                favorables al medio ambiente.</div>

                <div className="mx-top-text">En Broad-Ocean Motor de México estamos comprometidos en ofrecerle un valor agregado a nuestros clientes, 
                inversionistas y empleados de una manera sostenible.</div>

                <div className="mx-top-text">En Broad-Ocean Motor de México estamos comprometidos en el cumplimiento de las responsabilidades y obligaciones sociales, 
                buscando siempre un crecimiento a la par de nuestros socios comerciales.</div>

                <div className="mx-top-text">En Broad-Ocean Motor de México estamos comprometidos en ofrecer productos y servicios de alta calidad y eficiencia, a 
                través de la innovación y enfoque a clientes, asegurando el trato humano, su involucramiento y participación total en todos los niveles para sostener 
                la mejora continua y excelencia.</div>


            </div>
            </div> 


    </div>
);
}
export default Mexico;