import React, { useEffect,useRef, useState}from "react";
import './Product.css';
import Hsheet from './Hvacsheet'
import { Link} from "react-router-dom";
import file1 from '../Broad-Ocean Motor Product Catalogues.pdf'
import { getStorage, ref,getDownloadURL } from "firebase/storage";

import { UserAuth } from "../../Login/AuthContext";
function Hvac(){
  
  const { user, logout } = UserAuth();
  useEffect(() => {
    logout();
}, [])

return (

  <div className="hvacwrapper">
    <div className="hvac-title">Product Catalog--HVAC Applaction</div>
    <Hsheet/>
   
  <button className="hvac-download"> <Link to={file1} target="_blank" download>Download the whole Broad-Ocean Motor Products Catalog </Link></button>
  {/* <button className="hvac-download"> <Link to={file2} target="_blank" download>Download agttool20230404.rar</Link></button> */}
  {/* <button className="hvac-download"> <Link to={file3} target="_blank" download>Download agttool20230404.exe</Link></button> */}
  
  </div>





);




}
export default Hvac;