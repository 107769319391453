import "../../Careers/Careers.css";
import { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import { getFirestore,collection, getDocs,deleteDoc,doc,writeBatch } from "firebase/firestore";
import {app} from '../../firebase-config'
const db = getFirestore(app);
import { useNavigate,Link  } from 'react-router-dom'
import { AiOutlineLeft} from "react-icons/ai";
import { UserAuth } from "../AuthContext";


export default function DeleteView() {
  // const location = useLocation();
  const { user, logout } = UserAuth();
  const [buttonPopup, setButtonPopup] = useState(false);
  const navigate = useNavigate ()
  
  let [list, setlist] = useState([
  
  ]);

  async function fetchData() {
    // You can await here
    const response = await getDocs(collection(db, "users"));
    // ...
    const arr=[]
    response.forEach((doc) => {
      const docItem=doc.data()||{}
      docItem.id=doc.id
      arr.push(docItem)
     
    });
    setlist(arr)
  }
  useEffect( () => {
    
    fetchData();
    const handleBeforeUnload = (event) => {
      // Do something before the page is unloaded, such as displaying a confirmation dialog
      event.preventDefault();
      event.returnValue = '';
    };

    const handleUnload = () => {
      // Do something before the page is unloaded, such as sending data to the server
      logout();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      // Clean up the event listeners when the component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
   
  }, []);

  const open = (inx) => {
    console.log(inx);
    let copy = [...list];
    for (let i = 0; i < copy.length; i++) {
      if (inx === i) {
        console.log(copy[inx].isOpen);
        copy[inx].isOpen = !copy[inx].isOpen;
      }
    }
    setlist(copy);
  };

const handleDelete= async (item)=>{
  try{
    await deleteDoc(doc(db, "users", item.id));
    fetchData();
    // setisSuccessDelete(true);
    // setisClickDelete(false);
  }catch(err){
    console.log(err);
  }

}
const editItem = (index) => {
  setTimeout(()=>{
    navigate('/Update',{ state: { editItem: list[index] } })
  },500)
};
let [isClickDelete, setisClickDelete] = useState(false);

let [isSuccessDelete, setisSuccessDelete] = useState(false);
const deleteItem = (inx) => {

  setisClickDelete(true);
};
const closeModel = () => {
  setisSuccessDelete(false);
  setisClickDelete(false);
};

  return (
    <>     


    <div className="Deletewrapper">

    <div className="delete-top">
        <span className="delete-back"><Link to='/Account'><AiOutlineLeft size='25px'/>  Back</Link></span>

      
        <div className="add-title"> Edit/Delete Position</div>
      </div>
     
      
      
      <div className="delete-block">
        {list.map((item, index) => {
          return (
            <div className="Hitem" key={Math.random()}>
              <div className="careers-title-bar"  >
                     <button className="careers-button2" onClick={() => handleDelete(item)}>Delete</button>
                <button className="careers-button2" onClick={()=>(editItem(index))}>Edit</button>
                
                <div className="title-bar-title" onClick={() => {
                    open(index);
                    
                  }}>{item.jobTitle}</div>
                <div className="title-bar-location">{item.jobLocation}</div>
               
                
              </div>
              {item.isOpen && (
                <div className="HbottomContent">
                  <div className="HeachItem">
                    <div className="jd-title">Job Descrition</div>
                    <div className="jd-content">
                      <ui>
                        {item.jobDescription.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ui>
                    </div>
                  </div>
                  <div className="HeachItem">
                  <div className="jd-title">Key Duties and Responsibilities</div>
                    <div className="jd-content">
                      <ui>
                        {item.keyDutiesAndRes.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ui>
                    </div>
                  </div>
                  <div className="HeachItem">
                  <div className="jd-title">Minimum Education/Certification Requirements and Experience:</div>
                    <div className="jd-content">
                      <ui>
                        {item.Minimun.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ui>
                    </div>
                  </div>
                  <div className="HeachItem">
                  <div className="jd-title">Preferred Qualifications and Skills:</div>
                    <div className="jd-content">
                      <ui>
                        {item.skill.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ui>
                    </div>
                  </div>
                  <div className="HeachItem">
                    <div className="jd-title">Benefit:</div>
                    <div className="jd-content">
                      <ui>
                        {item.benefit.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </ui>
                    </div>
                  </div>
                </div>
              )}
              {isClickDelete === true && (
        <div className="model">
          <div>Are you sure you want to delete</div>
        
          <div className="BtnBox">
            <button
            onClick={()=>(handleDelete(index))}
            >
              Delete
            </button>
            <button onClick={closeModel}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {isSuccessDelete === true && (
        <div className="model">
          <div className="circle">√</div>
          <div>Position successFully Delete</div>
          <button className="Done" onClick={closeModel}>
            Done
          </button>
        </div>
      )}
            </div>
          );
        })}
      </div>
      <div className="careers-bottom-block"></div>
      
    </div>
    </>
  );
}