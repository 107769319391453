import React, { useEffect,useRef, useState}from "react";
import Section3 from '../Homepage/Section3/Section3'
import "./Houston.css";
import img1 from "./Houimg/houimg1.webp";
import img2 from "./Houimg/houimg2.webp";
import img3 from "./Houimg/houimg3.webp";
import img4 from "./Houimg/houimg4.webp";
import img5 from "./Houimg/houimg5.webp";
import img6 from "./Houimg/houimg6.webp";
import img7 from "./Houimg/houimg7.webp";
import img8 from "./Houimg/chi1.jpg";
import img9 from "./Houimg/chi2.jpg";
import img10 from "./Houimg/chi3.jpg";
import img11 from "./Houimg/chi4.jpg";
import img12 from "./Houimg/chi5.jpg";
import img13 from "./Houimg/chi6.jpg";
import img14 from "./Houimg/chi7.jpg";
import img15 from "./Houimg/chi8.jpg";
import img16 from "./Houimg/chi9.jpg";
import img17 from "./Houimg/houimg17.webp";
import { UserAuth } from "../Login/AuthContext";
function Houston() {
  const { user, logout } = UserAuth();


  useEffect(() => {
    logout();
}, [])

  return (
    <div className="houwrapper">
      <div className="hou-top">
        <div className="hou-top-img">
          <img className="hou-i" src={img1} />
        </div>
        <div className="hou-top-content">
          <div className="hou-top-title">Broad-Ocean Motor of Houston</div>
          <div className="hou-top-text">
            Broad-Ocean Motor of Houston is an autonomous, full-service resource
            serving the North American region with local, on-site distribution,
            validation and testing capabilities. Located in Waller, TX, we are a
            subsidiary of Broad-Ocean Motor Company, which is a China-based
            electric motor manufacturer serving global appliance, HVAC, and
            related industries. Broad-Ocean Motor serves as a source of
            distribution to major HVAC companies like Daikin. We also have a
            fully functional Test Lab that can conduct reliability testing,
            motor tear-down and analysis, and is equipped with a fully
            functional air tunnel test system and a dynamometer system. The
            company is committed into becoming a global extraordinary supplier
            for green motors and driving systems.
          </div>
        </div>
        <Section3/>
      </div>

      <div className="hou-mid">
        <div className="hou-mid-title">What We Do</div>
        <div className="hou-block">
          <div className="hou-block-left">
            <img className="hou-block-img" src={img2} />
          </div>
          <div className="hou-block-right">
            <div className="hou-block-title">Distribution</div>
            <div className="hou-block-text">
              The Broad-Ocean Motor’s warehouse is a 166,000 sq. foot facility
              with the capability to unload, store, and ship palletized product
              utilizing 17 dock doors. The facility is also temperature
              controlled, and we can store pallets on floor or rack locations.
              100% inventory accuracy is obtained as we use latest scanning
              hardware and software. In addition, we utilize a 3PL Warehouse
              Managing System, which allows us to store many different products
              for numerous clients. This WMS software allows outside clients to
              check their inventory and shipping operations in real time.
            </div>
          </div>
        </div>

        <div className="hou-block">
          <div className="hou-block-left">
            <img className="hou-block-img" src={img3} />
          </div>
          <div className="hou-block-right">
            <div className="hou-block-title">Reliability Testing</div>
            <div className="hou-block-text">
              In this facility we can test motor reliability through the use of
              extended run times.
              <li className="hou-li">Can test up to 16 motors at one time</li>
              <li className="hou-li">
                Blower static pressure can be adjusted to desired conditions
              </li>
              <li className="hou-li">
                Power supplies: 120, 208, 230, 480, 1 and 3 phase
              </li>
              <li className="hou-li">
                3-phase power meters to monitor motor input power
              </li>
              <li className="hou-li">
                Tachometers for angular velocity measurement
              </li>
              <li className="hou-li">
                Can test with continuous power or cycled power through use of
                programmable relays
              </li>
            </div>
          </div>
        </div>

        <div className="hou-block">
          <div className="hou-block-left">
            <img className="hou-block-img" src={img4} />
          </div>
          <div className="hou-block-right">
            <div className="hou-block-title">
              Motor Tear-Down and Inspection
            </div>
            <div className="hou-block-text">
              We have the ability to perform tear down analysis and quality
              inspection of defective or damaged motors through the use of:
              <li className="hou-li">Dielectric withstand (hi-pot) testing</li>
              <li className="hou-li">Resistance testing</li>
              <li className="hou-li">Loaded and no-load run testing</li>
            </div>
          </div>
        </div>
        <div className="hou-block">
          <div className="hou-block-left">
            <img className="hou-block-img" src={img17} />
          </div>
          <div className="hou-block-right">
            <div className="hou-block-title">Third-Party Logistics</div>
            <div className="hou-block-text">
              Broad-Ocean Motor provides warehousing and transportation services
              that can be scaled and customized to customers’ needs. In
              addition, we utilize a 3PL Warehouse Managing System, which allows
              us to store many different products for numerous clients. This WMS
              software allows outside clients to check their inventory and
              shipping operations in real time with 100% inventory accuracy.
              Organizations interested in outsourcing elements of its
              distribution, warehousing and fulfillment services should contact
              Broad-Ocean for more information.
            </div>
          </div>
        </div>

        <div>&nbsp;</div>
        <div className="hou-mid-title">State of the Art Laboratory</div>

        <div className="hou-block">
          <div className="hou-block-left">
            <img className="hou-block-img" src={img5} />
          </div>
          <div className="hou-block-right">
            <div className="hou-block-title">ASHRAE Tunnel Test System</div>
            <div className="hou-block-text">
              The airflow tunnel at BOMT allows us to test in-unit motor
              performance by measuring motor input power and angular velocity,
              as well as airflow produced by UUT. A back pressure fan provides
              the ability to adjust the external static pressure across the UUT.
              <li className="hou-li">Conforms to ASHRAE 37, 41</li>
              <li className="hou-li">
                Flow range: 300 to 10000 CFM using ASHRAE nozzles (3” to 10”)
              </li>
              <li className="hou-li">
                Power supplies: 120, 208, 230, 480, 1 and 3 phase
              </li>
              <li className="hou-li">
                Yokogawa WT333E power analyzer: V, A, W, PF
              </li>
              <li className="hou-li">
                Temperature, humidity, barometric pressure (absolute), external
                static pressure (differential)
              </li>
            </div>
          </div>
        </div>

        <div className="hou-block">
          <div className="hou-block-left">
            <img className="hou-block-img" src={img6} />
          </div>
          <div className="hou-block-right">
            <div className="hou-block-title">Magtrol Dynamometer</div>
            <div className="hou-block-text">
              Our dynamometer system allows us to quantify motor performance by
              measuring various properties including torque, angular velocity,
              electrical input power, and other variables. Our system includes:
              <li className="hou-li">DSP6001 Dynamometer Controller</li>
              <li className="hou-li">6530 Power Analyzer</li>
              <li className="hou-li">
                Power supplies: 120, 208, 230, 480, 1 and 3 phase
              </li>
              <li className="hou-li">
                STACO Power Supply 26V to 600V Single Phase
              </li>
              <li className="hou-li">
                Three Hysteresis Dynamometers, max. torque: 165 oz-ft
              </li>
              <li className="hou-li">M-TEST 5 Motor Testing Software</li>
            </div>
          </div>
        </div>

        <div className="hou-block">
          <div className="hou-block-left">
            <img className="hou-block-img" src={img7} />
          </div>
          <div className="hou-block-right">
            <div className="hou-block-title">Combustion Test Lab</div>
            <div className="hou-block-text">
              Combustion and furnace efficiency rating testing can be performed
              in our combustion test lab.
              <li className="hou-li">ANSI Z21.47, ASHRAE 103</li>
              <li className="hou-li">
                Siemens Ultramat 23 for CO and CO2 analysis
              </li>
              <li className="hou-li">
                Inficon Gas Chromatograph for gas heating value measurement
              </li>
              <li className="hou-li">
                National Instruments DAQ: 1. Temperature 2. Pressure 3. Gas flow
                rate
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <div className="column-block">
            <img className="hou-im" src={img8} />
            <img className="hou-im" src={img9} />
          </div>

          <div className="column-block">
            <img className="hou-im" src={img10} />
            <img className="hou-im" src={img11} />
          </div>
        </div>
        <div class="column">
          <div className="column-block">
            <img className="hou-im" src={img12} />
            <img className="hou-im" src={img13} />
          </div>
          <div className="column-block">
            <img className="hou-im" src={img14} />
            <img className="hou-im" src={img15} />
          </div>
        </div>
      </div>
      <div className="hou-top">
        <div className="hou-top-content">
          <div className="hou-top-title">
            Broad-Ocean Motor of Houston (Chicago Office)
          </div>
          <div className="hou-block-text">
            Business Development/Sales - Our Account Managers and Sales
            Engineers provide dedicated service to our customers to understand
            their current and emerging requirements. We provide solid,
            sustainable motor & motorized airflow solutions to our customers in
            the HVAC industry all across North America
          </div>
          <br></br>
          <div className="hou-block-text">
            R {"&"} D – Developing the products of the future for our North
            American customers. We work with customers to meet design
            specifications and requirements for today and tomorrow. Provide
            engineering support, onsite IT support and sales technical support.
          </div>
          <br></br>
          <div className="hou-block-text">
            Technical Support – We provide our customers with the highest degree
            of quality, and technical support. We value our relationships with
            our customers and are committed to exceeding their expectations
            through knowledge, support and collaboration.
          </div>
        </div>
      </div>

      <div classname="hou-top"></div>
    </div>
  );
}
export default Houston;
