import React from "react";
import { useState, useEffect } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { sliderData } from "./s4-data.js";
import "./Section4.css";

const Section4 = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = sliderData.length;

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 5000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
    console.log("next");
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
    console.log("prev");
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  return (
    <div className="s4-slider">
      <div className="s4-title">Our Customers</div>
      <div className="s4-block">
     
      <AiOutlineArrowLeft className="s4-arrow s4prev" onClick={prevSlide} />
      {sliderData.map((slide, index) => {
        return (
          <div
            className={index === currentSlide ? "s4-slide current" : "s4-slide"}
            key={index}
          >
            {index === currentSlide && (
              <div className="s4-main">
                
                <img className="s4-main-img" src={slide.image} alt="slide"  />
               
               
              </div>
            )}
          </div>
        );
      })}
       <AiOutlineArrowRight className="s4-arrow s4next" onClick={nextSlide} />
       </div>
      
    </div>
  );
};



export default Section4;