import React from "react";

import './Newspage.css';
import img from '../newsimg/newsimg4.webp'
function Newsfour(){


return (
 
  <div className="newspagewrapper">
      <div className="newspage-inner">
        <div className="newspage-title">Broad-Ocean Motor Co., Ltd. Acquires Prestolite Electric, LLC</div>
        <div className="newspage-date">April 16, 2015</div>
        <div className="newspage-img"><img className="np-img" src={img} /></div>
        <div className="newspage-text">Prestolite Electric, LLC is pleased to announce that Zhongshan Broad-Ocean Motor Co., Ltd. (Broad-Ocean Motor) completed its acquisition of Prestolite Electric, LLC and its subsidiaries on April 7, 2015. Terms of the deal were not disclosed.
This acquisition closely aligns the synergies of Broad-Ocean Motor and Prestolite Electric, resulting in world-class products and services to our global and regional customers. Prestolite’s full range of products now includes high output alternators and starter motors for a vast array of applications in numerous industries such as automotive, school, coach, and city buses, commercial truck, off-road equipment, and mining vehicles, in addition to large capacity diesel engine generators and marine.</div>
        <div className="newspage-text">According to the plan, the company intends to invest in Shanghai Shenganneng Industrial Co., Ltd., Ningbo Yunsheng( 8.860 , -0.02 , -0.23% ) Co., Ltd., Ningbo Yunsheng Investment Co., Ltd., Shanghai Ainaida Industrial Co., Ltd., and Ningbo Jianzhi Management Partnership (Limited Partnership), Beijing Zhongke Yineng New Technology Co., Ltd., Shenzhen Zhicheng Dongyuan Investment Partnership (Limited Partnership), Tibet Tianying Investment Co., Ltd., Ningbo Linshiyuan Investment Center (Limited Partnership), Ningbo Zhuoyu Investment Management Partnership (Limited Partnership) issued 426 million shares at an issue price of 6.34 yuan per share, and paid 799 million yuan in cash to purchase 100% of Shanghai Electric Drive.</div>
        <div className="newspage-text">Product offerings include 12, 24, 48, and other voltage ranges of alternators from 30 amperes to 600 amperes and starter motors from 0.7 kilowatt to 15 kilowatt, and motors and controllers of various output ranges, backed by a worldwide service and support network.</div>
        <div className="newspage-text">In January 2014, Broad-Ocean Motor acquired Prestolite Electric’s China alternator, starter motor, and electric vehicle component manufacturing operations. The current transaction completes the combined Broad Ocean-Prestolite global footprint, which spans Africa, Europe, Oceania, North, Central, and South America.
Together, Broad-Ocean Motor and Prestolite Electric will leverage each other’s strengths to enhance the product offerings and maximize growth potential across the globe.</div>
        <div className="newspage-text">“Broad-Ocean Motor has been serving its customers and growing its business for over 20 years. The Prestolite Electric acquisition will add value long-term for our shareholders and will allow us to further grow our business globally,” said Charles Lu, Chairman of Broad-Ocean Motor. “This acquisition will enable us to pursue our strategic plans to benefit our customers, suppliers, and employees.”</div>
        <div className="newspage-text">Broad-Ocean Motor is a global supplier of high-tech, high quality motors, alternators, and starters to multiple segments including appliances, air handlers, vehicle electrification, and light duty for the transportation sector. Broad-Ocean Motor was established in 1994 and is publicly traded in China.</div>
        <div className="newspage-text">Prestolite Electric is a global supplier of alternators, starters, and new energy solutions for commercial truck, bus, off-road equipment, military, and electric vehicles. Since 1911, Prestolite Electric has established a reputation of providing superior solutions to the transportation and industrial markets around the globe.</div>
      </div>




  </div>





);




}
export default Newsfour;