import React from "react";
import './Section3.css';
import s3logo from './s3image/s3logo.webp'
import Reactplayer from 'react-player';
function Section3(){


return (

  
<div className="section3wrapper" >
    {/* <div className="s3-logo"><img className="s3logo" src={s3logo} alt='Broad-ocean Motor-logo'/></div> */}
    {/* <div className="s3-title">TITLE HERE</div>
    <div className="s3-text">We are committed in becoming a Global Extraordinary Supplier for Green Motors and Driving Systems!</div>     */}
    <div className="video">

    <Reactplayer className="player"
    
        width='100%'
        height='100%'
    
    url='https://www.youtube.com/watch?v=hfphL5T5rGs' controls={true}/>

    </div>
    

</div>




);




}
export default Section3;