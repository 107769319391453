import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Survey.css";
//import Layout from "./Layout";
import Paper from "./Paper/index";
import Total from "./Total/index";
import { message } from "antd";
import { UserAuth } from "../Login/AuthContext";
import { Button } from 'antd';
import s3 from './img/s3.PNG'
import s4 from './img/CelebrationDinner Schedule.jpg'
const Survey = () => {
  const { user, logout } = UserAuth();
  
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/Signin');
      message.success('Logout successfully');
    } catch (e) {
      console.log(e.message);
    }
  };
  const scrolltop = () =>{
    window.scrollTo({top:0});
};

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Do something before the page is unloaded, such as displaying a confirmation dialog
      event.preventDefault();
      event.returnValue = '';
    };

    const handleUnload = () => {
      // Do something before the page is unloaded, such as sending data to the server
      logout();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      // Clean up the event listeners when the component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
      //logout();
    };


    
  },[]);





  return (
    <div className="surveywrapper">
      {/* <div className="surveytitle">2023 Broad-Ocean Motor, US Employee Satisfaction Questionnaire</div>
      <div className="surveyp1">Hello!<br></br>
In order to improve the company's management and operational efficiency, as well improve our employees experience in the company, we are asking you to participate in this employee satisfaction survey. Your participation is very important. Please fill in the form according to your experiences in the company, feelings about the company. Your honest feedback and constructive opinions are very important to the sustainable and healthy development of the company. We will keep your personal opinions and views strictly confidential, and the survey results are only used for continuous improvement of the company's management reference. Thank you for your support and cooperation!
</div>
<div className="surveyp1">Instructions for filling in the questionnaire:<br></br>
1. The first time user please click “Create a random survey key” before starting. Keep this key confidential to ensure confidentiality and to save your responses. Should you need to come back to the survey and complete it at a later date.<br></br>
2. Or you can create the survey key by yourself, type in anything you want in the input box and click "Find your Key or Create the key by yourself" to create the key by yourself.<br></br>
3. If you find that your new key is occupied, please create a new key.<br></br>
4. If you already have the key, please type in and click "Find your Key or Create the key by yourself" to find your survey.
</div> */}
<div className="survey-one">
{/* <img style={{ width: 250,height:300, }}  src={s3} alt='Bologo'/>   */}


<div className="survey-one-right">

<div className="surveytitle">
Broad-Ocean Motor 30th Anniversary Celebration Invitation - Event Questionnaires</div>
<div className="surveyp1">30 Years old, it is known as the "age of establishing oneself" in Chinese culture. Thank you for accompanying with us! Thank you for your understanding, trusting and supporting! This is the source of our forward momentum, Broad-Ocean Motor is looking forward to continuously delivering our best value to our valued customers in the future!</div>

<Link className="survey-link" to={s4} target="_blank" download>Download Celebration/Dinner Schedule </Link>

</div>

</div>

      <div className="survey-top">
        
      <div className="survey-top-letf"></div>
      
      <Paper/>
      <div className="survey-top-right"></div>




      </div>
      
      
      
      
       {/* <button><NavLink to='/Surveyresults'>Results</NavLink></button> */}
       {/* <div className='account-p'>Must logout when you finish the survey</div>
       <Link to='/Signin' onClick={scrolltop}>

  <Button type='primary' onClick={handleLogout} style={{ width: 100, marginTop: 18 }}>

  Logout

</Button></Link> */}

    </div>
  );
};



export default Survey;