import React from "react";

import './Section2.css';
import img1 from './s2image/s2img1.png';
import img2 from './s2image/s2img2.png';
import img3 from './s2image/s2img3.png'
import img4 from './s2image/s2img4.png'
function Section2(){


return (

  
<div className="section2wrapper">
  <div className="section2-inner">

    <div className="section2-title">Broad-Ocean Motor’s Projects</div>
    <div className="section2-block">
        <div className="section2-block-top">
        <div className="section2-block-inner">
            <div className="section2-block-img"><img className="s2-img" src={img2}/></div>
            <div className="s2b-c"><div className="section2-block-title">Public Transportation</div>
            <div className="section2-block-text">Integrated hybrid-electric powertrain solutions for transit buses.</div></div>
            


        </div>

        <div className="section2-block-inner">
            <div className="section2-block-img"><img className="s2-img" src={img1}/></div>
            <div className="s2b-c"><div className="section2-block-title">Construction</div>
            <div className="section2-block-text">Electric power solutions to allow customers to downsize the engine and improve fuel efficiency.</div></div>
            


        </div>
        </div>
        <div className="section2-block-top">
        <div className="section2-block-inner">
            <div className="section2-block-img"><img className="s2-img" src={img4}/></div>
            <div className="s2b-c"> <div className="section2-block-title">Passenger Cars</div>
            <div className="section2-block-text">Complete line of traction motors, inverters, and BSG’s for our customers.</div></div>
           


        </div>

        <div className="section2-block-inner">
            <div className="section2-block-img"><img className="s2-img" src={img3}/></div>
            <div className="s2b-c">   <div className="section2-block-title">Commercial Vehicles</div>
            <div className="section2-block-text">Powerful electric machines specifically designed to exceed the rigid requirements of heavy-duty truck manufacturers.</div></div>
         


        </div>
        </div>




    </div>


  </div>







</div>




);




}
export default Section2;