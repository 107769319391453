import React from "react";
import {NavLink} from 'react-router-dom';
import './News.css';
import img1 from './newsimg/newsimg1.webp'
import img2 from './newsimg/newsimg2.webp'
import img3 from './newsimg/newsimg3.webp'
import img4 from './newsimg/newsimg4.webp'
function News(){
    const scrolltop = () =>{
        window.scrollTo({top:0});
    };

return (
  
  <div className="newswrapper">
      <div className="news-inner">
      <div className="news-title">News Center</div>
      <div className="news-content">
      <div className="content-right">
            <div className="content-right-inner">
                <div className="content-right-title">Related News</div>
                <div className="content-right-text"><a href="http://www.broad-ocean.com/broad-ocean-cn/news.html">Broad-Ocean Motors Zhongshan</a></div>
                <div className="content-right-text"><a href="http://www.prestolite-bj.com/news">Prestolite Beijing</a></div>
                <div className="content-right-text"><a href="https://www.chinaedrive.com/cate-46605-40853.html">Shanghai Edrive</a></div>
                <div className="content-right-text"><a href="http://en.whgnr.com/info.php?class_id=102">Wuhu GNR</a></div>

            </div>





        </div>
        <div className="content-left">
            <div className="news-block">
                <div className="news-block-img"><img src={img1} /></div>
                <div className="news-block-content">
                    <div className="news-block-title"><NavLink to='/Newsone' onClick={scrolltop}>Tata AutoComp signs JV with Prestolite Electric to supply EV powertrain solutions in India</NavLink></div>
                    <div className="news-block-text">Homegrown auto component maker Tata AutoComp Systems on Friday...</div>
                    <div className="news-block-date">January 24, 2020</div>
                </div>
            </div>
            <div className="news-block">
                <div className="news-block-img"><img src={img2} /></div>
                <div className="news-block-content">
                    <div className="news-block-title"><NavLink to='/Newstwo' onClick={scrolltop}>Ballard Power Systems today announced that a $28.3 million strategic equity investment made by Zhongshan Broad-Ocean Motor Co., Ltd. has closed</NavLink></div>
                    <div className="news-block-text">The Broad-Ocean investment, initially announced in a press release on...</div>
                    <div className="news-block-date">July 26, 2016</div>
                </div>
            </div>
            <div className="news-block">
                <div className="news-block-img"><img src={img3} /></div>
                <div className="news-block-content">
                    <div className="news-block-title"><NavLink to='/Newsthree' onClick={scrolltop}>Broad-Ocean Motor’s 3.5 billion RMB Yuan acquisition of Shanghai Electric Drive</NavLink></div>
                    <div className="news-block-text"> Dayang( 5.220 , 0.05 , 0.97% ) (002249) released a fixed increase plan on...</div>
                    <div className="news-block-date">July 23, 2015</div>
                </div>
            </div>
            <div className="news-block">
                <div className="news-block-img"><img src={img4} /></div>
                <div className="news-block-content">
                    <div className="news-block-title"><NavLink to='/Newsfour' onClick={scrolltop}>Broad-Ocean Motor Co., Ltd. Acquires Prestolite Electric, LLC</NavLink></div>
                    <div className="news-block-text">Prestolite Electric, LLC is pleased to announce that Zhongshan Broad-Ocean...</div>
                    <div className="news-block-date">April 24, 2015</div>
                </div>
            </div>



        </div>
       







      </div>
      </div>
  </div>





);




}
export default News;