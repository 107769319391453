import Card from '../UI/Card';
import { Button } from '@mui/material';
import OpenInNew from '@mui/icons-material/OpenInNew';
import DeleteAllIcon from '@mui/icons-material/Clear';
import styles from './FileFolder.module.css';

const FileFolder = props => {
    return (
        <Card>
            <p className={styles.folder}>
                <div className={styles.block} > <Button
                    variant="contained"
                    size='medium'
                    className={styles['MuiButton-contained']}
                    endIcon={<OpenInNew />}
                    href={`/filelinks/${props.Token}`}
                    target='_blank'
                >Access Link</Button>
                <span className={styles.spacer}/> Time Created: {props.Created}
                

             
                    
                    
                    </div>
               
               <br/>
                
                {props.Files}
            </p>
            <button
                    className={styles.Button}
                    title="Delete Folder"
                    onClick={async () => await props.Delete(
                        props.Files.map(file => file.key))}
                    >
                        <DeleteAllIcon fontSize='medium'/>
                    </button>
        </Card>
    );
}

export default FileFolder;