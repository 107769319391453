import React from "react";

import './Section5.css';
import Back from "./Backvideo.mp4"
function Section5(){


return (

  
<div className="section5wrapper">
{/* <div className="overlay"></div> */}
<video className="section5video" src={Back} autoPlay loop muted />
<div className="section5-text">
    <p className="s5-p1">Broad-Ocean Motor</p>
    <p className="s5-p2"> Driving the Earth</p>


</div>

</div>




);




}
export default Section5;