import { database } from "../../firebase-config";
import { update, ref, get } from "firebase/database";
import { uid } from "uid";

export default class DatabaseController {
    CreateKeyName = fileName => {
        const processedKey = fileName.replaceAll(
            '.', '').replaceAll('#', '').replaceAll(
                '$', '').replaceAll('/', '').replaceAll(
                    '[', '').replaceAll(']', '');

        return processedKey;
    }

    CreateBatch = async (fileNames, userID) => {
        const batchToken = uid(); // Generates an 11-character unique token

        const updates = {};
        
        for (const fileName of fileNames) {
            const processedKey = this.CreateKeyName(fileName);

            const InABatch = await this.InBatch(processedKey, null);

            if (!InABatch) {
                updates[`batchTable/${processedKey}`] = batchToken;
                updates[`userTable/${processedKey}`] = userID;
            }
        }
        update(ref(database), updates);
    }

    // Delete miscellaneous assortment of files
    DeleteFiles = async fileNames => {
        const updates = {};

        for (const fileName of fileNames) {
            const processedKey = this.CreateKeyName(fileName);

            updates[`batchTable/${processedKey}`] = null;
            updates[`userTable/${processedKey}`] = null;
        }
        update(ref(database), updates);
    }

    // Delete all files in a batch
    DeleteBatch = batchToken => {

    }

    // Gets the token of the batch that the file belongs to
    GetBatch = async fileName => {
        const processedKey = this.CreateKeyName(fileName);

        const snapshot = await get(ref(database, `batchTable/${processedKey}`));

        if (snapshot.exists()) {
            return snapshot.val();
        }
    }

    // Gets the uid of the user that the file belongs to
    GetUser = async fileName => {
        const processedKey = this.CreateKeyName(fileName);

        const snapshot = await get(ref(database, `userTable/${processedKey}`));

        if (snapshot.exists()) {
            return snapshot.val();
        }
    }

    // Check if file is in any or a specific batch
    InBatch = async (fileName, batchToken) => {
        const snapshot = await get(ref(database, `batchTable/${fileName}`));

        // No token passed in means we're searching all batches
        if (!batchToken) {
            return snapshot.exists();
        }
        else {
            if (snapshot.exists()) {
                return snapshot.val() === batchToken;
            }
        }
        return false;
    }

    IsAdmin = async userID => {
        const snapshot = await get(ref(database, `adminTable/${userID}`));

        return snapshot.exists();
    }

    // CheckConnection = async () => {
    //     const snapshot = await get(child(ref(database), '.info/connection'));

    //     return snapshot.val();
    // }
}