import React from "react";
import {FaBars, FaTimes,} from 'react-icons/fa';
import './Product.css'
import Catalog from './Catalog/Catalog'

class Hvacsheet extends React.Component{
    
    state = {
        showsection1: true,
        showsection2: false,
        showsection3: false,
        showsection4: false,
        showsection5: false,
        showsection6: false,
        showsection7: false,
        showsection8: false,
        showsection9: false,
        visible: false,
        
        
    }
  
    scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

     render(){

        const {showsection1,showsection2,showsection3,showsection4,showsection5,showsection6,showsection7,showsection8,showsection9,visible ,select} = this.state;
        
         return(
                <div className="hvacsheetwrapper">
                   <div className='slide_nav'>     
                <p className="slide_nav_button" onClick={() => this.setState({visible: !visible })}><FaBars size='30px'/></p>
            </div>
            <div className="hvacworksheet-top" >
                       
                       <div className="hvac-section-menu">
                           <div className="hsm-buttom"  onClick={() =>[ this.setState({
                               showsection1: true,
                               showsection2: false,
                               showsection3: false,
                               showsection4: false,
                               showsection5: false,
                               showsection6: false,
                               showsection7: false,
                               showsection8: false,
                               showsection9: false,
                               
                           
                           }),
                           this.scrollToTop()]
                           }   >
                           
                           <div className="hsm-title" style={{ color : (showsection1 ? '#4C75DF' : 'black') }}>A/C: Outdoor Motor</div>
                           
                           </div>

                       </div>
                       <div className="hvac-section-menu">
                           <div className="hsm-buttom"  onClick={() => [this.setState({
                               showsection1: false,
                               showsection2: true,
                               showsection3: false,
                               showsection4: false,
                               showsection5: false,
                               showsection6: false,
                               showsection7: false,
                               showsection8: false,
                               showsection9: false,
                           
                           }),
                           this.scrollToTop()]} >
                           
                           <div className="hsm-title" style={{ color: (showsection2 ? '#4C75DF' : 'black') }}>A/C: Indoor Motor</div>
                           
                           </div>

                       </div>
                      
                       <div className="hvac-section-menu">
                           <div className="hsm-buttom" onClick={() => [this.setState({
                               showsection1: false,
                               showsection2: false,
                               showsection3: true,
                               showsection4: false,
                               showsection5: false,
                               showsection6: false,
                               showsection7: false,
                               showsection8: false,
                               showsection9: false,
                           
                           }),
                           this.scrollToTop()]} >
                           
                           <div className="hsm-title" style={{ color: (showsection3 ? '#4C75DF' : 'black') }}>A/C: Fan Coil Motor</div>
                           
                           </div>

                       </div>

                       <div className="hvac-section-menu">
                           <div className="hsm-buttom"  onClick={() => [this.setState({
                               showsection1: false,
                               showsection2: false,
                               showsection3: false,
                               showsection4: true,
                               showsection5: false,
                               showsection6: false,
                               showsection7: false,
                               showsection8: false,
                               showsection9: false,
                           
                           }),
                           this.scrollToTop()]} >
                           
                           <div className="hsm-title" style={{ color: (showsection4 ? '#4C75DF' : 'black') }}>A/C: Window Machine</div>
                           
                           </div>

                       </div>

                       <div className="hvac-section-menu">
                           <div className="hsm-buttom" onClick={() => [this.setState({
                               showsection1: false,
                               showsection2: false,
                               showsection3: false,
                               showsection4: false,
                               showsection5: true,
                               showsection6: false,
                               showsection7: false,
                               showsection8: false,
                               showsection9: false,
                           
                           }),
                           this.scrollToTop()]} >
                           
                           <div className="hsm-title"style={{ color: (showsection5 ? '#4C75DF' : 'black') }}>Gas Furnace: Draft Inducer</div>
                           
                           </div>

                       </div>

                       <div className="hvac-section-menu">
                           <div className="hsm-buttom" onClick={() => [this.setState({
                               showsection1: false,
                               showsection2: false,
                               showsection3: false,
                               showsection4: false,
                               showsection5: false,
                               showsection6: true,
                               showsection7: false,
                               showsection8: false,
                               showsection9: false,
                           
                           }),
                           this.scrollToTop()]} >
                           
                           <div className="hsm-title" style={{ color: (showsection6 ? '#4C75DF' : 'black') }}>Pump Motor</div>
                           
                           </div>

                       </div>

                       <div className="hvac-section-menu">
                           <div className="hsm-buttom" onClick={() => [this.setState({
                               showsection1: false,
                               showsection2: false,
                               showsection3: false,
                               showsection4: false,
                               showsection5: false,
                               showsection6: false,
                               showsection7: true,
                               showsection8: false,
                               showsection9: false,
                           
                           }),
                           this.scrollToTop()]} >
                           
                           <div className="hsm-title"style={{ color: (showsection7 ? '#4C75DF' : 'black') }}>Washing Machine Motor</div>
                           
                           </div>

                       </div>

                       <div className="hvac-section-menu">
                           <div className="hsm-buttom" onClick={() => [this.setState({
                               showsection1: false,
                               showsection2: false,
                               showsection3: false,
                               showsection4: false,
                               showsection5: false,
                               showsection6: false,
                               showsection7: false,
                               showsection8: true,
                               showsection9: false,
                           
                           }),
                           this.scrollToTop()]} >
                           
                           <div className="hsm-title" style={{ color: (showsection8 ? '#4C75DF' : 'black') }}>Refrigeration Equipment Motor</div>
                           
                           </div>

                       </div>

                       <div className="hvac-section-menu">
                           <div className="hsm-buttom" onClick={() => [this.setState({
                               showsection1: false,
                               showsection2: false,
                               showsection3: false,
                               showsection4: false,
                               showsection5: false,
                               showsection6: false,
                               showsection7: false,
                               showsection8: false,
                               showsection9: true,
                           
                           }),
                           this.scrollToTop()]} >
                           
                           <div className="hsm-title" style={{ color: (showsection9 ? '#4C75DF' : 'black') }}>Automatic Door Motor</div>
                           
                           </div>

                       </div>

                     
                       </div>
           
                    <div className="hvacworksheet-top-mobile" style={{ display: (visible ? 'flex' : 'none') }}>
                       
                        <div className="hvac-section-menu">
                            <div className="hsm-buttom" onClick={() => [this.setState({
                                showsection1: true,
                                showsection2: false,
                                showsection3: false,
                                showsection4: false,
                                showsection5: false,
                                showsection6: false,
                                showsection7: false,
                                showsection8: false,
                                showsection9: false,
                                visible:false,
                            }),
                            this.scrollToTop()]} >
                            
                            <div className="hsm-title">A/C: Outdoor Motor</div>
                            
                            </div>

                        </div>
                        <div className="hvac-section-menu">
                            <div className="hsm-buttom" onClick={() => [this.setState({
                                showsection1: false,
                                showsection2: true,
                                showsection3: false,
                                showsection4: false,
                                showsection5: false,
                                showsection6: false,
                                showsection7: false,
                                showsection8: false,
                                showsection9: false,
                                visible:false,
                            }),
                            this.scrollToTop()]} >
                            
                            <div className="hsm-title">A/C: Indoor Motor</div>
                            
                            </div>

                        </div>
                       
                        <div className="hvac-section-menu">
                            <div className="hsm-buttom" onClick={() => [this.setState({
                                showsection1: false,
                                showsection2: false,
                                showsection3: true,
                                showsection4: false,
                                showsection5: false,
                                showsection6: false,
                                showsection7: false,
                                showsection8: false,
                                showsection9: false,
                                visible:false,
                            }),
                            this.scrollToTop()]} >
                            
                            <div className="hsm-title">A/C: Fan Coil Motor</div>
                            
                            </div>

                        </div>

                        <div className="hvac-section-menu">
                            <div className="hsm-buttom"  onClick={() => [this.setState({
                                showsection1: false,
                                showsection2: false,
                                showsection3: false,
                                showsection4: true,
                                showsection5: false,
                                showsection6: false,
                                showsection7: false,
                                showsection8: false,
                                showsection9: false,
                                visible:false,
                            }),
                            this.scrollToTop()]} >
                            
                            <div className="hsm-title">A/C: Window Machine</div>
                            
                            </div>

                        </div>

                        <div className="hvac-section-menu">
                            <div className="hsm-buttom" onClick={() => [this.setState({
                                showsection1: false,
                                showsection2: false,
                                showsection3: false,
                                showsection4: false,
                                showsection5: true,
                                showsection6: false,
                                showsection7: false,
                                showsection8: false,
                                showsection9: false,
                                visible:false,
                            }),
                            this.scrollToTop()]} >
                            
                            <div className="hsm-title">Gas Furnace: Draft Inducer</div>
                            
                            </div>

                        </div>

                        <div className="hvac-section-menu">
                            <div className="hsm-buttom" onClick={() => [this.setState({
                                showsection1: false,
                                showsection2: false,
                                showsection3: false,
                                showsection4: false,
                                showsection5: false,
                                showsection6: true,
                                showsection7: false,
                                showsection8: false,
                                showsection9: false,
                                visible:false,
                            }),
                            this.scrollToTop()]} >
                            
                            <div className="hsm-title">Pump Motor</div>
                            
                            </div>

                        </div>

                        <div className="hvac-section-menu">
                            <div className="hsm-buttom" onClick={() => [this.setState({
                                showsection1: false,
                                showsection2: false,
                                showsection3: false,
                                showsection4: false,
                                showsection5: false,
                                showsection6: false,
                                showsection7: true,
                                showsection8: false,
                                showsection9: false,
                                visible:false,
                            }),
                            this.scrollToTop()]} >
                            
                            <div className="hsm-title">Washing Machine Motor</div>
                            
                            </div>

                        </div>

                        <div className="hvac-section-menu">
                            <div className="hsm-buttom" onClick={() => [this.setState({
                                showsection1: false,
                                showsection2: false,
                                showsection3: false,
                                showsection4: false,
                                showsection5: false,
                                showsection6: false,
                                showsection7: false,
                                showsection8: true,
                                showsection9: false,
                                visible:false,
                            }),
                            this.scrollToTop()]} >
                            
                            <div className="hsm-title">Refrigeration Equipment Motor</div>
                            
                            </div>

                        </div>

                        <div className="hvac-section-menu">
                            <div className="hsm-buttom" onClick={() => [this.setState({
                                showsection1: false,
                                showsection2: false,
                                showsection3: false,
                                showsection4: false,
                                showsection5: false,
                                showsection6: false,
                                showsection7: false,
                                showsection8: false,
                                showsection9: true,
                                visible:false,
                            }),
                            this.scrollToTop()]} >
                            
                            <div className="hsm-title">Automatic Door Motor</div>
                            
                            </div>

                        </div>

                      
                        </div>

                        <div className="hvacsectionwrapper">
                        {showsection1 && (<Section1/>)}
                        {showsection2 && (<Section2/>)}
                        {showsection3 && (<Section3/>)}
                        {showsection4 && (<Section4/>)}
                        {showsection5 && (<Section5/>)}
                        {showsection6 && (<Section6/>)}
                        {showsection7 && (<Section7/>)}
                        {showsection8 && (<Section8/>)}
                        {showsection9 && (<Section9/>)}

                        </div>

                </div>
         );
     }

}

const Section1 = () =>(
<div className="hvac-section-inner">
        
        <Catalog/>

</div>

) 
const Section2 = () =>(
    <div className="hvac-section-inner">
    
    <Catalog/>
    
    </div>
    
    ) 
    const Section3 = () =>(
        <div className="hvac-section-inner">
        
        <Catalog/>
        
        </div>
        
        ) 

 const Section4 = () =>(
        <div className="hvac-section-inner">
        <Catalog/>
        
        </div>
        
        ) 
 const Section5 = () =>(
        <div className="hvac-section-inner">
        
        <Catalog/>
        
        </div>
        
        ) 
 const Section6 = () =>(
        <div className="hvac-section-inner">
        
        <Catalog/>
        
        </div>
        
        ) 
 const Section7 = () =>(
        <div className="hvac-section-inner">
        
        <Catalog/>
        
        </div>
        
        ) 
 const Section8 = () =>(
        <div className="hvac-section-inner">
        
        <Catalog/>
        
        </div>
        
        ) 
 const Section9 = () =>(
        <div className="hvac-section-inner">
        
        <Catalog/>
        
        </div>
        
        ) 


export default Hvacsheet;