import react,{useRef} from "react";
import './Pop.css'
import emailjs from 'emailjs-com';
function Popup(props){

    const form = useRef();
    
    const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_nxnrdzh', 'template_yrbcdql', form.current, 'ZN81IQBaykDmyWr-E')
      .then(
        function(response) {
            //console.log("SUCCESS. status=%d, text=%s", response.status, response.text);
            alert("Email sent successfully!");
        },
        
      (error) => {
          alert.log(alert.text);
      },
    
      );
      form.current.reset();
  };
    return(props.trigger) ? (
        <div className="popup">
            <div className="popup-inner">
                <div className="popup-block">
                    
                <div className="pop-title">Application</div>
                <form className="pop-form" enctype="multipart/form-data" method="post" ref={form} onSubmit={sendEmail}>
                <p className="pop-p">Name:
                
                </p>
                <input className="pop-frame" required={true} name='name' type="text"  />
                <p className="pop-p">Position you are applying for:
                
                </p>
                <input className="pop-frame" required={true} name="title" type="text"  />
                <p className="pop-p">Email:
                
                </p>
                <input className="pop-frame" required={true} name="email" type="email"  />
                <p className="pop-p">Phone:
                
                </p>
                <input  className="pop-frame" name="phone" type="tel"   />
                <p className="pop-p">Resume (PDF-500KB Max)</p>
                <input className="cfile" type="file" name="resume" accept=".pdf,.doc,.docx,.txt" data-max-file-size="10000000" /> 
                <p></p>
                <div className="pop-down">
                <input  className="pop-button" type="submit" value='Submit' />           

                <button className="pop-button" onClick={()=>props.setTrigger(false)}>Close</button>
                </div>
                </form>



                
                {props.choldren}

                </div>
            </div>
        </div>
    ) : "";



}


export default Popup;