import React ,{useState,useEffect }from "react";
import {NavLink} from 'react-router-dom';
import './About.css';
import img2 from './Aboutimg/aboutimg2.webp'
import img3 from './Aboutimg/aboutimg3.webp'
import img4 from './Aboutimg/aboutimg4.webp'
import img5 from './Aboutimg/aboutimg5.webp'
import img6 from './Aboutimg/aboutimg6.webp'
import img7 from './Aboutimg/aboutimg7.webp'
import img8 from './Aboutimg/aboutimg8.webp'
import Abs from './Absection'
import video1 from './video1.mp4'
import Gallery from './Gallery'
import { UserAuth } from "../Login/AuthContext";
function About(){
    const scrolltop = () =>{
        window.scrollTo({top:0});
    };
    const [isShownHoverContent, setIsShownHoverContent] = useState(false);
    const [isShownHoverContent1, setIsShownHoverContent1] = useState(false);
    const [isShownHoverContent2, setIsShownHoverContent2] = useState(false);
    const [isShownHoverContent3, setIsShownHoverContent3] = useState(false);
    const [isShownHoverContent4, setIsShownHoverContent4] = useState(false);
    const [isShownHoverContent5, setIsShownHoverContent5] = useState(false);
    const { user, logout } = UserAuth();

    useEffect(() => {
        logout();
   }, [])
 
return (
 
  <div className="aboutwrapper">
      <div className="about-top">
            {/* <div className="about-top-title">Broad-Ocean’s Mission</div>
            <div className="about-top-text">Focus on green and environmentally friendly solutions in the field of motors and drive systems, customer first, innovation driven, open cooperation, realize value for our customers, investors, employees and other partners, surpass ourselves, and faithfully fulfill social responsibilities</div> */}
            {/* <Abs/>   */}

      </div>
      <div className="about-section">
        <div className="about-inner">
        <div className="about-title">Company Overview</div>
        <div className="about-overview">
        <div className="about-overview-right">
            {/* <img className="about-overview-img1" src={img8}/> */}
            <video className="about-overview-video" src={video1}  controls="controls" />
            </div>
            <div className="about-overview-left">
                <div className="about-overview-text">Broad-Ocean Motor is an electrical appliance manufacturer started in China and does business in domestic and overseas markets. The company operates its businesses through four segments:</div>
                <div>&nbsp;</div>
                <div className="about-overview-text">The Household Appliances and Household Electrical Appliances segment provides air conditioning load motors, washing machines/dryer motors and others.</div>
                <div>&nbsp;</div>
                <div className="about-overview-text">The New Energy Vehicle Powertrain System segment provides new energy vehicles drive motors and controllers, among others.</div>
                <div>&nbsp;</div>
                <div className="about-overview-text">The Vehicle Rotating Electrical Appliances segment includes traditional car starters and generators. </div>
                <div>&nbsp;</div>
                <div className="about-overview-text">The New Energy Vehicles Operating Platform segment is engaged in the new energy car rental and Internet custom car, new energy vehicle charging station operation, charge pile construction and the provision of other operation and supporting services.</div>
            </div>
           

        </div>

        </div>

      </div>
      <div className="about-section">
            <div className="about-inner">
            <div className="about-title">Our Initiatives</div>
            <div className="about-mid-block">
                <div className="about-mid-left"
                onMouseEnter={() => setIsShownHoverContent(true)}
                onMouseLeave={() => setIsShownHoverContent(false)}
                
                >   
                <img className="about-init-img" src={img2}/>
                {isShownHoverContent && (
                <div className="about-hover">
                    
                     <div className="university-left">
            <div className="about-hover-title">University Outreach</div>
            <div className="about-hover-text">
We are looking for talent from University of Michigan and Wayne State University with an interest in participating in advanced engineering research projects.</div>
            <button className="about-hover-button"><NavLink to='/University' onClick={scrolltop}>Learn More</NavLink></button>
                </div>
                </div>
                     )}


                </div>

                <div className="about-mid-right"
                onMouseEnter={() => setIsShownHoverContent1(true)}
                onMouseLeave={() => setIsShownHoverContent1(false)}
                
                >   
                <img className="about-init-img" src={img3}/>
                {isShownHoverContent1 && (
                <div className="about-hover">
                      <div className="university-right">
            <div className="about-hover-title">Department of Energy Standards</div>
            <div className="about-hover-text">Broad-Ocean Motor is on the forefront of industry energy efficiency efforts and develops motors that meet or exceed legislative requirements</div>
            
            <button className="about-hover-button"><a href="https://www1.eere.energy.gov/buildings/appliance_standards/standards.aspx?productid=6&action=viewlive">Learn More</a></button>
            </div>
                </div>
                     )}


                </div>
            </div>
            </div>
      </div>

<div className="about-section">
      <div className="about-inner">
      <div className="about-title">Our Culture </div>
        <Gallery/>
        
        </div>
      </div>
      <div className="about-section ">
      
            <div className="about-inner">
            <div className="about-title">Our Locations</div>
            <div className="about-mid-block">
                <div className="about-mid-left"
                onMouseEnter={() => setIsShownHoverContent2(true)}
                onMouseLeave={() => setIsShownHoverContent2(false)}
                
                >   
                <img className="about-location-img" src={img4}/>
                {isShownHoverContent2 && (
                    <NavLink to='/Mic' onClick={scrolltop}>
                <div className="about-hover">
                    
                     <div className="about-location-left">
            <div className="about-location-title">Broad-Ocean Technologies</div>
            <div className="about-location-text">Research & Development</div>
            
                </div>
                </div>
                </NavLink>
                     )}


                </div>

                <div className="about-mid-right"
                onMouseEnter={() => setIsShownHoverContent3(true)}
                onMouseLeave={() => setIsShownHoverContent3(false)}
                
                >   
                <img className="about-location-img" src={img5}/>
                {isShownHoverContent3 && (
                    <NavLink to='/Hou' onClick={scrolltop}>
                <div className="about-hover">
                      <div className="about-location-right">
            <div className="about-location-title">Broad-Ocean Motor Houston</div>
            <div className="about-location-text">Testing & Distribution</div>
            
            
            </div>
                </div>
                </NavLink>
                     )}


                </div>
            </div>


            <div className="about-mid-block">
                
                <div className="about-mid-left"
                onMouseEnter={() => setIsShownHoverContent4(true)}
                onMouseLeave={() => setIsShownHoverContent4(false)}
                
                >   
                <img className="about-location-img" src={img6}/>
                {isShownHoverContent4 && (
                    <NavLink to='/Ind' onClick={scrolltop}>
                <div className="about-hover">
                    
                     <div className="about-location-left">
            <div className="about-location-title">Broad-Ocean Motor Indiana</div>
            <div className="about-location-text">Testing & Distribution</div>
            
                </div>
                </div>
                </NavLink>
                     )}


                </div>

                <div className="about-mid-right"
                onMouseEnter={() => setIsShownHoverContent5(true)}
                onMouseLeave={() => setIsShownHoverContent5(false)}
                
                >   
                <img className="about-location-img" src={img7}/>
                {isShownHoverContent5 && (
                    <NavLink to='/Mx' onClick={scrolltop}>
                <div className="about-hover">
                      <div className="about-location-right">
            <div className="about-location-title">Broad-Ocean Motor Mexico</div>
            <div className="about-location-text">Distribution</div>
            
            
            </div>
                </div>
                </NavLink>
                     )}


                </div>
            </div>


        </div>
      </div>




  </div>





);




}
export default About;