import "./styles.css";
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate} from "react-router-dom";
import Header from './Layout/Header'
import Footer from './Layout/Footer'
import Home from './Homepage/Home'
import Contact from './Contactus/Contact'
import Location from './Location/Location'
import Careers from "./Careers/Careers";
import Newsone from './News/News-page/Newsone'
import News from './News/News'
import Newstwo from './News/News-page/Newstwo'
import Newsthree from './News/News-page/Newsthree'
import Newsfour from './News/News-page/Newsfour'
import About from './About/About'
import University from './About/University'
import Hou from './Houston/Houston'
import Ind from './Indiana/Indiana'
import Mic from './Michigan/Michigan'
import Mx from './Mexico/Mexico'
import Hvac from './Product/HVAC/Hvac'
import EV from './Product/EV/EV'
import Update from './Login/Delete/Update'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Signin from './Login/signin'
import Account from './Login/Account';
import Add from './Login/Add/Add';
import Delete from './Login/Delete/Delete'
import { AuthContextProvider } from './Login/AuthContext'
import ProtectedRoute from './Login/ProtectedRoute';
import Survey from "./Survey/Survey";
import Surveyresults from "./Survey/Surveryresults";
import 'antd/dist/antd.css';
import FileUpload from "./File/FileManagement/FileUpload";
import FileLinks from "./File/FileManagement/FileLinks";

export default function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyBN5BHiFOttkVgl3F_Ue-NWBwUzmXJmCC8",
    authDomain: "bomnaweb-88ab3.firebaseapp.com",
    projectId: "bomnaweb-88ab3",
    storageBucket: "bomnaweb-88ab3.appspot.com",
    messagingSenderId: "729878209672",
    appId: "1:729878209672:web:e9baa6c25d4b634a646b3d",
    measurementId: "G-4X2CGDH7DQ"
  };
  
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const dataRetrieval = useState(false);


  return (
    <div className="App">
      <Router>
      <Header/>
      <AuthContextProvider>
      <Routes>
      <Route path="/Home" element={<Home />} />
      <Route path="/" element={<Navigate replace to="/Home" />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Location" element={<Location />} />
      <Route path="/Careers" element={<Careers />} />
      <Route path="/About" element={<About />} />
      <Route path="/News" element={<News />} />
      <Route path="/Newsone" element={<Newsone />} />
      <Route path="/Newstwo" element={<Newstwo />} />
      <Route path="/Newsthree" element={<Newsthree />} />
      <Route path="/Newsfour" element={<Newsfour />} />
      <Route path="/University" element={<University />} />
      <Route path="/Hou" element={<Hou />} />
      <Route path="/Ind" element={<Ind/>} />
      <Route path="/Mic" element={<Mic/>} />
      <Route path="/Mx" element={<Mx/>} />
      <Route path="/Hvac" element={<Hvac/>} />
      <Route path="/EV" element={<EV/>} />
      <Route path="/Signin" element={<Signin/>} />
      
   
      <Route path="/Survey" element={
          <ProtectedRoute>
                  <Survey /> 
          </ProtectedRoute>
      } />
      <Route path="/Surveyresults" element={
          <ProtectedRoute>
            <Surveyresults />
          </ProtectedRoute>
    } />
      <Route path="/FileUpload" element={
          <ProtectedRoute>
                   <FileUpload DataRetrieval={dataRetrieval} /> 
          </ProtectedRoute>
      } />
      <Route path="/FileLinks/:batchToken" element={
          
          <FileLinks DataRetrieval={dataRetrieval} 
          HeaderTxt="Files Available to Download Below" 
          StandAlone={true}/> 
          
      } />
      <Route
            path='/Account'
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
        <Route
            path='/Add'
            element={
              <ProtectedRoute>
                <Add />
              </ProtectedRoute>
            }
          />
        <Route
            path='/Delete'
            element={
              <ProtectedRoute>
                <Delete />
              </ProtectedRoute>
            }
          />
       <Route
            path='/Update'
            element={
              <ProtectedRoute>
                <Update />
              </ProtectedRoute>
            }
          />
      
      </Routes>
      </AuthContextProvider>
      <Footer/>
      </Router>
    </div>
  );
}

