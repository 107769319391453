import React ,{ useEffect }from 'react';

import { UserAuth } from './AuthContext';
import {Link} from "react-router-dom";
import './login.css';

function Account (){
  const { user, logout } = UserAuth();
  //const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/Signin');
      message.success('Logout successfully');
    } catch (e) {
      console.log(e.message);
    }
  };
  const scrolltop = () =>{
    window.scrollTo({top:0});
};
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Do something before the page is unloaded, such as displaying a confirmation dialog
      event.preventDefault();
      event.returnValue = '';
    };

    const handleUnload = () => {
      // Do something before the page is unloaded, such as sending data to the server
      logout();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      // Clean up the event listeners when the component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  },[]);

  return (
    <div className='accountwrapper'>

    <h1 className='account-title'>HR Job System</h1>

    <p>Current login account: {user && user.email}</p>
    
   <Link to='/Add'>
    <button  className='hr-button'>

      Add Position

    </button></Link>

    <Link to='/Delete'>

    <button  className='hr-button'>

      Edit/Delete Position

    </button></Link>

    <Link to='/Signin' onClick={scrolltop}>
                <button type='primary' onClick={handleLogout} className="hr-button">Logout</button>
                </Link>

  </div>
  )
};

export default Account;