import React, { useEffect,useRef, useState} from "react";
import { UserAuth } from "../Login/AuthContext";
import './Indiana.css';
import img1 from './Indimg/indimg1.webp'
import img2 from './Indimg/indimg2.webp'
import img3 from './Indimg/indimg3.webp'
import img4 from './Indimg/indimg4.webp'
import img5 from './Indimg/indimg5.webp'
import img6 from './Indimg/indimg6.webp'
import img7 from './Indimg/indimg7.webp'
import img8 from './Indimg/indimg8.webp'
import img9 from './Indimg/indimg9.webp'
import img10 from './Indimg/indimg10.webp'
function Indiana(){
    const { user, logout } = UserAuth();
    useEffect(() => {
        logout();
   }, [])
return (
    <div className="indwrapper">
            <div className="ind-top">
             <div className="ind-top-img">
                 <img className="ind-i" src={img1}/>
                 </div>
                 <div className="ind-top-content">  
            <div className="ind-top-title">Broad-Ocean Motor of Indiana</div>
            <div className="ind-top-text">Broad-Ocean Motor
                of Indiana is an autonomous, full-service resource serving the North American region with local, on-site distribution, validation and testing capabilities.</div>
                <div className="ind-top-text">Located in Whitestown, Indiana, Broad-Ocean Motor Indiana is a subsidiary of Zhongshan Broad-Ocean Motor Company, a
China-based electric motor manufacturer serving global appliance, HVAC, and related industries. Broad-Ocean Motor serves as a source of distribution to major HVAC companies like Carrier Corporation and Johnson Controls Inc. We also have a fully functional Test Lab that can conduct reliability testing, motor tear-down and analysis, and is equipped with a fully functional air tunnel test system and a dynamometer system. The company is committed into becoming a global extraordinary supplier for green motors and driving systems.</div>

            </div>
            </div> 

    <div className="ind-mid">
        <div className="ind-mid-title">What We Do</div>
        <div className="ind-block">
            <div className="ind-block-left"><img className="ind-block-img" src={img10} /></div>
            <div className="ind-block-right">
                <div className="ind-block-title">Third-Party Logistics</div>
                <div className="ind-block-text">Broad-Ocean Motor of Indiana provides warehousing and transportation services that can be scaled and customized to customers’ needs. In addition, we utilize a 3PL Warehouse Management System, which allows us to store many different products for numerous clients. This WMS software allows outside clients to check their inventory and shipping operations in real time with 100% inventory accuracy. Organizations interested in outsourcing elements of its distribution, warehousing and fulfillment services should contact 317-769-8701 for more information.
</div>
            </div>
        </div>
        <div className="ind-block">
            <div className="ind-block-left"><img className="ind-block-img" src={img2} /></div>
            <div className="ind-block-right">
                <div className="ind-block-title">Distribution</div>
                <div className="ind-block-text">The Broad-Ocean Motor’s warehouse is a 86,000 sq. foot facility with the capability to unload, store, and ship pallatalized product.  The facilities has 13 dock doors, is temperature controlled, and has floor and rack storage. 100% inventory accuracy is obtained through our Warehouse Management System developed by BOM, which allows us to store many different products for numerous clients.  This WMS software allows outside clients to check their inventory and shipping operations in real time.
</div>
            </div>
        </div>

        <div className="ind-block">
            <div className="ind-block-left"><img className="ind-block-img" src={img3} /></div>
            <div className="ind-block-right">
                <div className="ind-block-title">Motor Tear-Down and Inspection</div>
                <div className="ind-block-text">We have the ability to perform tear down analysis and quality inspection on defective and damaged motors through the use of:
                <li className="ind-li">Dielectric withstand (hi-pot) testing</li>
                <li className="ind-li" >Resistance testing</li>
                <li className="ind-li">Loaded and no-load run testing</li>
               

                </div>
            </div>
        </div>



            <div>&nbsp;</div>
        <div className="hou-mid-title">State of the Art Laboratory</div>

        <div className="ind-block">
            <div className="ind-block-left"><img className="ind-block-img" src={img4} /></div>
            <div className="ind-block-right">
                <div className="ind-block-title">Magtrol Motor Test System</div>
                <div className="ind-block-text">Our dynamometer system allows us to quantify motor performance by measuring various properties including torque, angular velocity, electrical input power, and other variables.
Our system includes:
                <li className="ind-li">DSP7000 Dynamometer Controller</li>
                <li className="ind-li">6530 Power Analyzer</li>
                <li className="ind-li">STACO Power Supply 26V to 600V 3 Phase</li>
                <li className="ind-li">M-TEST 7 Motor Testing Software</li>
                <li className="ind-li">Four Dynamometers range from 3.3 Oz*Ft to 166.7 Oz*Ft</li>
                </div>
            </div>
        </div>


        {/* <div className="ind-block">
            <div className="ind-block-left"><img className="ind-block-img" src={img5} /></div>
            <div className="ind-block-right">
                <div className="ind-block-title">Furnace Lab</div>
                <div className="ind-block-text">Combustion and furnace efficiency rating testing can be performed in our combustion test lab.
                <li className="ind-li">ANSI Z21.47, ANSI/ASHRAE 103</li>
                <li className="ind-li">Combustion, AFUE, and FER testing</li>
                <li className="ind-li">Siemens Ultramat 23 for CO and CO2 analysis</li>
                <li className="ind-li">Inficon 3000 Micro Gas Chromatograph for gas heating value measurement</li>
                <li className="ind-li">Yokogawa WT1600 Digital Power Meter</li>
                <li className="ind-li">Labview DAQ</li>
                </div>
            </div>
        </div> */}


        <div className="ind-block">
            <div className="ind-block-left"><img className="ind-block-img" src={img6} /></div>
            <div className="ind-block-right">
                <div className="ind-block-title">ASHRAE 2500 SCFM Tunnel Test System</div>
                <div className="ind-block-text">The airflow tunnel at BOM-Indiana allows us to test in-unit motor performance by measuring motor input power and angular velocity, as well as airflow produced by UUT. A back pressure fan provides the ability to adjust the external static pressure across the UUT.
                <li className="ind-li">Conforms to ASHRAE 37, 41 and 51</li>
                <li className="ind-li">Flow range: 100 to 2500 SCFM, +/-1%</li>
                <li className="ind-li">ASHRAE nozzle: 1.6” to 6”</li>
                <li className="ind-li">LabVIEW DAQ based on Allen-Bradley Micrologix 1400 PLC, 16Bit A/D and D/A</li>
                <li className="ind-li">Temperature, Humidity, Pressure</li>
                </div>
            </div>
        </div>




        <div className="ind-block">
            <div className="ind-block-left"><img className="ind-block-img" src={img7} /></div>
            <div className="ind-block-right">
                <div className="ind-block-title">ASHRAE 4000 SCFM Tunnel Test System</div>
                <div className="ind-block-text">The airflow tunnel at BOM-Indiana allows us to test in-unit motor performance by measuring motor input power and angular velocity, as well as airflow produced by UUT. A back pressure fan provides the ability to adjust the external static pressure across the UUT.
                <li className="ind-li">Conforms to ASHRAE 37, 41 and 51</li>
                <li className="ind-li">Flow range: 100 to 4000 SCFM, +/-1%</li>
                <li className="ind-li">ASHRAE nozzle: 2”, 3”, 5.5” and 8”</li>
                <li className="ind-li">LabVIEW DAQ based on Allen-Bradley Micrologix 1400 PLC, 16Bit A/D and D/A</li>
                <li className="ind-li">Temperature, Humidity, Pressure</li>
                </div>
            </div>
        </div>


        <div className="ind-block">
            <div className="ind-block-left"><img className="ind-block-img" src={img8} /></div>
            <div className="ind-block-right">
                <div className="ind-block-title">CSZ Halt Chamber</div>
                <div className="ind-block-text">Stability testing, basic temperature cycling and accelerated stress testing can be performed
                <li className="ind-li">EZT-570-i Touch Screen Controller</li>
                <li className="ind-li">Temperature Range: -100°C to 200°C</li>
                <li className="ind-li">Rapid Temperature Ramp Rates at {'>'}90°C/min</li>
                <li className="ind-li">Vibration Range: 1 GRMS to 90 GRMS</li>
                <li className="ind-li">Fog-Free View Window and Interior Light</li>
                <li className="ind-li">Two side ports for ease of cable routing</li>
                <li className="ind-li">30” x 30” x 30” Interior Space</li>
                </div>
            </div>
        </div>


        <div className="ind-block">
            <div className="ind-block-left"><img className="ind-block-img" src={img9} /></div>
            <div className="ind-block-right">
                <div className="ind-block-title">CSZ Temperature Chamber</div>
                <div className="ind-block-text">Stability testing, basic temperature cycling and accelerated stress testing can be performed
                <li className="ind-li">EZT-570-i Touch Screen Controller</li>
                <li className="ind-li">Temperature Range: -34°C to 190°C with Forced Air</li>
                <li className="ind-li">Humidity Range: 10% to 98% RH</li>
                <li className="ind-li">Hermetically sealed unit to prevent moisture migration</li>
                <li className="ind-li">Fog-Free View Window and Interior Light</li>
                <li className="ind-li">Two 4” ports for ease of cable routing</li>
                <li className="ind-li">38” x 38” x 38” Interior Space</li>
                <li className="ind-li">Optional CSZ EZ-View Software for DAQ, Network Access, and Cloud Backup</li>
                
                </div>
            </div>
        </div>

    </div>



    </div>
  





);




}
export default Indiana;